import React from "react"
import { useFormikContext, useField } from "formik"
import Select from "react-select"
import classnames from "classnames"
import { FormGroup, Label, FormFeedback } from "reactstrap"

export default function Input({ label, id, name, options = {}, inputClass, required = false }) {
  const { values, errors, handleChange, handleBlur, touched } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} className="col-form-label">
          {label}
          {required && (
            <>
              <span className="text-danger">*</span>
            </>
          )}
        </Label>
      )}
      <Select
        name={name}
        id={id}
        options={options}
        placeholder={label}
        className={classnames({
          "error-select": errors[name]
        })}
        classNamePrefix="select2-selection"
        value={values[name] || null}
        onChange={value => helpers.setValue(value)}
        onBlur={() => helpers.setTouched(true)}
      />
      {errors[name] && <FormFeedback style={{ display: "block" }}>{errors[name]}</FormFeedback>}
    </FormGroup>
  )
}
