import React, { forwardRef } from "react"

const AgHttps = forwardRef((props, ref) => {
  return (
    <>
      <span>
        <i
          className={
            props.data.https === true ? "fas fa-check" : "" || props.data.https === false ? "fas fa-times" : ""
          }
          style={{ color: props.data.https === true ? "#1eff00" : "#ff0000" }}
        />{" "}
        {props.data.https}
      </span>
    </>
  )
})

AgHttps.displayName = "AgHttps"

export default AgHttps
