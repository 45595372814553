import React, { Component, forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })
  return (
    <>
      {props.value !== "" && (
        <Button
          onClick={() => {
            props.setItem(props.data)
            props.toggle()
          }}
        >
          {props.view === true ? t("View") : props.text}
        </Button>
      )}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
