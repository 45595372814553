import { Col, Row, Modal, ModalBody, Card, CardBody, Container, Input, Button, Form, FormFeedback } from "reactstrap"
import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const ModalEvisa = props => {
  let { item, isOpen, toggle, redownImage } = props

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-dialog">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Detail")}
          </h5>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container>
            <Row>
              <Col xl="12">
                <Card className="w-100">
                  <CardBody className="d-flex justify-content-between p-0">
                    <Col lg="9">
                      <Card className="">
                        <h4 className="pb-3">{props.t("Passport photo")}:</h4>
                        <img
                          className="rounded-3 img-fluid"
                          src={item.passport_img}
                          alt="Passport Image"
                          // style={{ maxWidth: "auto", minHeight: "300px" }}
                        />
                      </Card>
                    </Col>
                    <Col lg="3" className="ps-4">
                      <h4 className="pb-3"> {props.t("Customer information")}:</h4>
                      <h5>
                        <b> {props.t("Fullname")}: </b> {item.name}
                      </h5>
                      <h5>
                        <b>{props.t("Date of birth")}: </b> {item.dob}
                      </h5>
                      <h5>
                        <b>{props.t("Gender")}: </b> {item.sex === true ? props.t("Male") : props.t("Female")}
                      </h5>
                      <h5>
                        <b>{props.t("Passport number")}: </b> {item.personal_id}
                      </h5>
                      <h5>
                        <b>{props.t("Nationality")}: </b> {item.country}
                      </h5>
                      <h5>
                        <b>{props.t("Date of Entry")}: </b> {item.entry_date}
                      </h5>
                      <h5>
                        <b>{props.t("Departure Date")}: </b> {item.departure_date}
                      </h5>
                      <h5>
                        <b>{props.t("Number of days of stay")}: </b> {item.stayIn} {props.t("days")}{" "}
                      </h5>
                      {/* <h5>
                        <b>{props.t("Entry purpose")}: </b> {item.visaType_title}
                      </h5> */}
                      <h5>
                        <b>{props.t("Value of visa")}: </b> {item.multipleEntry_title}
                      </h5>
                      <h5>
                        <b>{props.t("Urgent")}: </b> {item?.fee?.title_vn}{" "}
                      </h5>
                      <h5>
                        <b>Vip: </b> {item.vip === true ? props.t("Yes") : props.t("No")}{" "}
                      </h5>
                      <h5>
                        <b>{props.t("Visa receiving place")}: </b> {item.addToGetVisa}
                      </h5>
                      {/* <Card className="d-flex flex-wrap gap-2 mt-2">
                        <Col className="d-flex">
                          <button
                            type="button"
                            onClick={async () => await redownImage(item._id)}
                            className="btn btn-primary me-3"
                          >
                            <i className="bx bx-smile font-size-16 align-middle me-2"></i>{" "}
                            Tải lại ảnh
                          </button>
                          <button type="submit" className="btn btn-success ">
                            <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                            Gửi hồ sơ
                          </button>
                        </Col>
                      </Card> */}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalEvisa.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalEvisa))
