import React, { useState, useCallback, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Row, Col, TabContent, TabPane, Card, CardBody, Alert } from "reactstrap"

import { Link } from "react-router-dom"
import { CreatePartner, CreateConfigPartner } from "../../../services/"
import Swal from "sweetalert2"

import NavStep from "./Element/NavStep"
import ContentStep1 from "./Element/ContentStep1"
import ContentStep2 from "./Element/ContentStep2"

const CreateNewPartner = () => {
  //meta title
  document.title = "Create new partner"
  const [activeTab, setactiveTab] = useState("1")
  const [data, setData] = useState({ username: "" })
  const refFormInfo = useRef(null)
  const [message, setMessage] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const onDismissAlert = () => setShowAlert(false)

  const navigate = useNavigate()

  const handleSubmitInfo = useCallback(
    async (values, actions) => {
      setData({ ...data, ...values, code: values.username })

      let profile = {
        username: values.username,
        password: values.password,
        fullname: values.fullname,
        email: values.email,
        dob: values.dob,
        gender: values.gender.value,
        country: values.country.value,
        phone: values.phone,
        address: values.address,
        code: values.username
      }

      try {
        const response = await CreatePartner(profile)
        if (response.status === 201) {
          setactiveTab(String(Math.min(Number(activeTab) + 1, 2)))
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.msg || "An error occurred while creating a new partner. Please try again",
          icon: "error",
          timer: 2000
        })
      }
    },
    [activeTab, data]
  )

  const handleSubmit = useCallback(async () => {
    if (!data) return

    let urgent_model_CVT = []
    if (data?.urgent_model_CVT != undefined && data?.urgent_model_CVT?.length > 0) {
      data.urgent_model_CVT.map((value, index) => urgent_model_CVT.push({ ...value, id: index + 1 }))
    }

    let urgent_model_EVISA = []
    if (data?.urgent_model_EVISA != undefined && data?.urgent_model_EVISA?.length > 0) {
      data.urgent_model_EVISA.map((value, index) => urgent_model_EVISA.push({ ...value, id: index + 1 }))
    }

    let urgentModel = {
      urgent_model_CVT: urgent_model_CVT,
      urgent_model_EVISA: urgent_model_EVISA,
      ma_doi_tac: data.username
    }

    try {
      const response = await CreateConfigPartner(urgentModel)
      if (response.status === 201) {
        Swal.fire({
          title: "Success",
          text: "Create Partner successfully",
          icon: "success",
          timer: 2000
        })
        setData({ username: "" })
        setactiveTab("1")
        refFormInfo.current.resetForm()
        navigate("/partner/list")
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.msg || "An error occurred while creating a new partner. Please try again",
        icon: "error",
        timer: 2000
      })
    }
  }, [data])

  const handleNext = useCallback(() => {
    setMessage("")
    setShowAlert(false)
    switch (activeTab) {
      case "1":
        refFormInfo.current.submitForm()
        break
      case "2":
        // Check bắt buộc thêm urgent_model_CVT và urgent_model_EVISA
        // let urgent_model_CVT = data?.urgent_model_CVT?.length;
        // let urgent_model_EVISA = data?.urgent_model_EVISA?.length;

        // if (urgent_model_CVT == undefined || urgent_model_CVT <= 0) {
        //   setMessage('You have not added urgent_model_CVT');
        //   setShowAlert(true);
        // } else if (urgent_model_EVISA == undefined || urgent_model_EVISA <= 0) {
        //   setMessage('You have not added urgent_model_EVISA');
        //   setShowAlert(true);
        // } else {
        handleSubmit()
        // }
        break
    }
  }, [activeTab, data, setMessage, setShowAlert, handleSubmit])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="checkout-tabs">
            <Row>
              <Col lg="2" sm="3">
                <NavStep activeTab={activeTab} setactiveTab={setactiveTab} />
              </Col>
              <Col lg="10" sm="9">
                <Card>
                  <CardBody>
                    {message && (
                      <Alert color="danger" isOpen={showAlert} toggle={onDismissAlert}>
                        {message}
                      </Alert>
                    )}
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <ContentStep1 onSubmit={handleSubmitInfo} refFormInfo={refFormInfo} />
                      </TabPane>
                      <TabPane tabId="2" id="v-pills-confir" role="tabpanel">
                        <ContentStep2 data={data} setData={setData} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Row className="mt-4">
                  <Col sm="12">
                    <div className="text-sm-end">
                      <Link className="btn btn-success" onClick={() => handleNext()}>
                        {Number(activeTab) < 2 && (
                          <>
                            NEXT <i className="bx bx-right-arrow-alt"></i>
                          </>
                        )}
                        {Number(activeTab) == 2 && (
                          <>
                            <i className="bx bxs-paper-plane me-1"></i> SUBMIT
                          </>
                        )}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateNewPartner
