import React, { createContext, useState } from "react"
import Loading from "../components/Common/Loading"
// Initiate Context
const LoadingContext = createContext()
// Provide Context
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  return (
    <React.Fragment>
      <Loading loading={loading} />
      <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>
    </React.Fragment>
  )
}

export default LoadingContext
