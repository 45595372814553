import axios from "./axios"

export function OCRPassport(data) {
  return axios.post("/mindee", data)
}

export function SendPassport(data) {
  return axios.post("/passport/create", data)
}

export function GetSentList(data) {
  return axios.post("/passport/sent/list", data)
}

export function GetChinaDataList(data) {
  return axios.get("/china/list", data)
}

export function RedownImage(data) {
  return axios.post("/china/redown", data)
}

export function ListBCAResult() {
  return axios.get("/dichvucong/list")
}

export function GetUserFee(type) {
  return axios.get(`/user/doitac/fee?type=${type}`)
}

export function CreatePartner(data) {
  return axios.post("/admin/doitac/create", data)
}

export function UpdatePartner(data) {
  return axios.post("/admin/doitac/edit", data)
}

export function CreateConfigPartner(data) {
  return axios.post("/admin/doitac/config/create", data)
}

export function UpdateConfigPartner(data) {
  return axios.post("/admin/doitac/config/edit", data)
}

export function ListPartnerResult(params) {
  return axios.get("/admin/doitac/list", {
    params: params
  })
}

export function getPartnerResult(id) {
  return axios.get("/admin/doitac/get?id=" + id)
}

export function GetDashboardInfo() {
  return axios.get("/admin/dashboard/get_info")
}

export function GetMindeeList(params) {
  return axios.get("/admin/mindee/list", {
    params: params
  })
}
export function GetProxyList(params) {
  return axios.get("/admin/proxies/list", {
    params: params
  })
}

export function CreateNewMindee(data) {
  return axios.post("/admin/mindee/create", data)
}

export function UpdateMindeeKey(data) {
  return axios.post("/admin/mindee/update", data)
}

export function CreateNewProxy(data) {
  return axios.post("/admin/proxy/create", data)
}

export function UpdateProxy(data) {
  return axios.post("/admin/proxy/update", data)
}

export function GetListWorker(params) {
  return axios.get("/admin/nhanvien/list", {
    params: params
  })
}

export function CloneUserDoitac(data) {
  return axios.post("admin/clone_new_user", data)
}

export function ListEvisaResult(params) {
  return axios.get("/admin/evisa/list", {
    params: params
  })
}

export function ListPassportResult(params) {
  return axios.get("/admin/passport/list", {
    params: params
  })
}

export function ListPassportVerifiedResult(params) {
  return axios.get("/admin/passport/verified/list", {
    params: params
  })
}

export function ListResultBCAResult(params) {
  return axios.get("/admin/result_bca/list", {
    params: params
  })
}

export function ListEvisaFakeInfo(params) {
  return axios.get("/admin/evisa/fake_info/list", {
    params: params
  })
}

export function CreateNewEvisaFakeInfo(data) {
  return axios.post("/admin/evisa/fake_info/create", data)
}

export function UpdateEvisaFakeInfo(data) {
  return axios.post("/admin/evisa/fake_info/edit", data)
}

export function RemoveEvisaFakeInfo(id) {
  return axios.get("/admin/evisa/fake_info/remove/" + id)
}

export function GetPassportByMaHoSo(so_ho_so) {
  return axios.get(`/admin/passport/detail/${so_ho_so}`)
}

export function ManualFetchDVCHoSo(data) {
  return axios.post("/admin/result_bca/update/manual", data)
}

export function ToolCheckEvisa(data) {
  return axios.post("/admin/evisa/check", data)
}

// export function GetListUserLabel() {
//   return axios.get(`/user/doitac/list/label`)
// }

export function GetDetailHoSoAndPassport(ma_ho_so) {
  return axios.get(`/admin/result_bca/get_both/${ma_ho_so}`)
}

export function GetStatisticalDoitac(data) {
  return axios.post("/admin/doitac/statistical", data)
}