import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import React, { useEffect, useState, useRef, useCallback } from "react"

import ReactDataGrid from "@inovua/reactdatagrid-enterprise"
import "@inovua/reactdatagrid-enterprise/index.css"
import SelectFilter from "@inovua/reactdatagrid-enterprise/SelectFilter"
import { GetChinaDataList } from "services"
import Swal from "sweetalert2"
import Cookies from "universal-cookie"

import { STORAGE_KEY } from "../../configs/app.const"
import { DATA_GRID_KEY } from "../../configs"
//services
import { SendPassport, RedownImage } from "../../services/"

const cookies = new Cookies()

const ListChinaData = () => {
  const [selected, setSelected] = useState({})
  const gridRef = useRef(null)

  const onSelectionChange = useCallback(({ selected, data, unselected }) => {
    if (selected === true) {
      setSelected(data)
    } else {
      setSelected(selected)
    }
  }, [])

  //meta title
  document.title = "VNPassport | List China Data"
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const gridStyle = { minHeight: 750, marginTop: 10 }

  const columns = [
    { header: "Trạng thái", name: "status", defaultWidth: 100, textAlign: "center" },
    { header: "Ngày tạo", name: "createAt", defaultWidth: 135, textAlign: "center" },
    { header: "Họ & tên", name: "name", defaultWidth: 135, textAlign: "center" },
    { header: "Gửi", name: "send", defaultWidth: 135, textAlign: "center" },
    { header: "Ngày tháng năm sinh", name: "birth", defaultWidth: 200, textAlign: "center" },
    {
      header: "Giới tính",
      name: "sex",
      defaultWidth: 135,
      textAlign: "center",
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: [
          { id: "Nam", label: "Nam" },
          { id: "Nữ", label: "Nữ" }
        ]
      },
      render: ({ value }) => value
    },
    { header: "Số hộ chiếu", name: "personal_id", defaultWidth: 135, textAlign: "center" },
    { header: "Quốc tịch", name: "country", defaultWidth: 135, textAlign: "center" },
    { header: "Ngày nhập cảnh", name: "entry_date", defaultWidth: 135, textAlign: "center" },
    { header: "Ngày xuất cảnh", name: "departure_date", defaultWidth: 135, textAlign: "center" },
    { header: "Khẩn cấp", name: "urgent", defaultWidth: 135, textAlign: "center" },
    { header: "VIP", name: "vip", defaultWidth: 135, textAlign: "center" },
    { header: "Nơi nhận thị thực", name: "addToGetVisa", defaultWidth: 450, textAlign: "center" }
  ]

  const filterValue = [
    { name: "name", operator: "startsWith", type: "string", value: "" },
    { name: "sex", operator: "inlist", type: "select", value: "" },
    { name: "personal_id", operator: "startsWith", type: "string", value: "" }
  ]

  const convertKhanCap = value => {
    switch (value) {
      case "0":
        return "Không"
      case "1":
        return "Khẩn trong ngày"
      case "2":
        return "Khẩn 1 ngày"
      case "3":
        return "Khẩn 2 ngày"
      case "4":
        return "Khẩn 3 ngày"
      default:
        return ""
    }
  }

  const updateList = (item, index) => {
    setData(prevData => {
      let items = [...prevData]
      let update_item = {
        ...item,
        send: (
          <Button
            onClick={() => {
              Swal.fire({
                title: "",
                html: `<img src="${item.passport_img}" crossorigin="anonymus" style="max-width: 100%; max-height: "auto";" />`
              })
            }}
          >
            Xem ảnh
          </Button>
        ),
        status: "Đã gửi"
      }
      items[index] = update_item
      return items
    })
  }

  const sendButton = (item, temp, index) => {
    if (item.isSend === false) {
      return (
        <>
          <Button
            color="success"
            onClick={e => {
              Swal.fire({
                html:
                  `<img src="${item.passport_img}" crossorigin="anonymus" alt="Passport Image" style="max-width: 100%; max-height: "auto";"/></br>` +
                  `<b>Họ và tên: </b> ${item.name} </br>` +
                  `<b>Ngày tháng năm sinh: </b> ${item.birth} </br>` +
                  `<b>Giới tính: </b> ${item.sex === 0 ? "Nam" : "Nữ"} </br>` +
                  `<b>Số hộ chiếu: </b> ${item.personal_id}</br>` +
                  `<b>Quốc tịch: </b> ${item.country}</br>` +
                  `<b>Ngày nhập cảnh: </b> ${item.entry_date}</br>` +
                  `<b>Ngày xuất cảnh: </b> ${item.departure_date}</br>` +
                  `<b>Khẩn cấp: </b>${convertKhanCap(item.urgent)}</br>` +
                  `<b>Vip: </b>${item.vip === "1" ? "Có" : "Không"}</br>` +
                  `<b>Nơi nhận thị thực: </b>${
                    item.chinaoption && item.chinaoption.length > 0 ? item.chinaoption[0].addToGetVisa : ""
                  }</br>`,
                showCancelButton: true,
                cancelButtonText: "Hủy",
                confirmButtonText: "Gửi",
                reverseButtons: true,
                showDenyButton: true,
                denyButtonText: "Tải lại ảnh"
              }).then(pop => {
                if (pop.isConfirmed && item.chinaoption[0]) {
                  Swal.fire({
                    title: "",
                    text: "Hệ thống đang xử lý",
                    icon: "warning",
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false
                  })

                  const fullName = item.name
                  const spacePos = fullName.indexOf(" ")
                  const surname = fullName.slice(0, spacePos)
                  const name = fullName.slice(spacePos + 1)

                  SendPassport({
                    _id: item._id,
                    surname: surname,
                    name: name,
                    dob: item.birth,
                    sex: item.sex === 0 ? "F" : "M",
                    country: item.country,
                    personal_id: item.personal_id,
                    entry_date: item.entry_date,
                    departure_date: item.departure_date,
                    stayIn: 15,
                    multipleEntry: 0,
                    create_date: "",
                    expriation_date: "",
                    vip: item.vip,
                    urgent: item.urgent,
                    wayToGetVisa: item.chinaoption[0].wayToGetVisa,
                    addToGetVisa: item.chinaoption[0].addToGetVisa,
                    code: cookies.get(STORAGE_KEY.madoitac),
                    passport_img: item.passport_img,
                    fromChina: 1
                  })
                    .then(res => {
                      Swal.fire({
                        title: "Passport sent successfully",
                        icon: "success",
                        timer: 3000,
                        timerProgressBar: true
                      })
                      updateList(item, index)
                    })
                    .catch(err => {
                      Swal.fire({
                        title: "Error",
                        text: `System sent passport error: ${err}`,
                        icon: "error",
                        timer: 3000,
                        timerProgressBar: true
                      })
                    })
                } else if (pop.isDenied) {
                  Swal.fire({
                    title: "",
                    text: "System is re-downloading image",
                    icon: "warning",
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false
                  })
                  RedownImage({ url: item.passport_img })
                    .then(res => {
                      Swal.fire({
                        title: "",
                        html: `<img src="${item.passport_img}" crossorigin="anonymus" style="max-width: 100%; max-height: "auto";" />`
                      })
                    })
                    .catch(err => {
                      Swal.fire({
                        title: "Error",
                        text: `Error at redownImage ${err.msg}`
                      })
                    })
                }
              })
            }}
          >
            Gửi
          </Button>
        </>
      )
    } else if (item.isSend === true) {
      return (
        <>
          <Button
            onClick={() => {
              Swal.fire({
                title: "",
                html: `<img src="${item.passport_img}" crossorigin="anonymus" style="max-width: 100%; max-height: "auto";" />`
              })
            }}
          >
            Xem ảnh
          </Button>
        </>
      )
    }
  }

  useEffect(() => {
    GetChinaDataList({})
      .then(res => {
        let temp = []
        let response = res.data
        response.map((item, index) => {
          temp.push({
            status: item.isSend && item.isSend === true ? "Đã gửi" : "Chưa gửi",
            createAt: item.createAt,
            name: item.name,
            birth: item.birth,
            sex: item.sex === 1 ? "Nam" : "Nữ",
            personal_id: item.personal_id,
            country: item.country,
            entry_date: item.entry_date,
            departure_date: item.departure_date,
            urgent: convertKhanCap(item.urgent),
            vip: item.vip === "0" ? "Không" : "Có",
            addToGetVisa: item.chinaoption && item.chinaoption.length > 0 ? item.chinaoption[0].addToGetVisa : "",
            send: sendButton(item, temp, index)
          })
        })
        setData(temp)
      })
      .catch(err => {})
  }, [])

  return (
    <>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Row>
            <Col>
              <div>
                <ReactDataGrid
                  licenseKey={DATA_GRID_KEY}
                  idProperty="id"
                  columns={columns}
                  style={gridStyle}
                  pagination
                  dataSource={data}
                  defaultFilterValue={filterValue}
                  editable={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ListChinaData
