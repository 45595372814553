import React, { useCallback, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Table,
  Input,
  CardTitle,
  Badge,
  Button,
  ButtonGroup,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap"

export default function ContentStep2({ data = {}, setData }) {
  const refFirstCVT = useRef(null)
  const [editCVT, setEditCVT] = useState(false)
  const [isAddCVT, setIsAddCVT] = useState(false)
  const [showModalCVT, setShowModalCVT] = useState(false)
  const toggleModalCVT = () => {
    setShowModalCVT(!showModalCVT)
    setEditCVT(false)
  }

  const refFirstEVISA = useRef(null)
  const [editEVISA, setEditEVISA] = useState(false)
  const [isAddEVISA, setIsAddEVISA] = useState(false)
  const [showModalEVISA, setShowModalEVISA] = useState(false)
  const toggleModalEVISA = () => {
    setShowModalEVISA(!showModalEVISA)
    setEditEVISA(false)
  }

  const validationSchema = Yup.object({
    title_vn: Yup.string().required("Please Enter title VN"),
    title_en: Yup.string().required("Please Enter title EN"),
    gia_ban: Yup.number().required("Please Enter Price").min(0, "Price must be greater than or equal to 0"),
    gia_von: Yup.number().required("Please Enter Cost").min(0, "Cost must be greater than 0"),
    vip_fee: Yup.number().required("Please Enter VIP Fee").min(0, "VIP Fee must be greater than 0"),
    service_fee: Yup.number().required("Please Enter Service Fee").min(0, "Service Fee must be greater than 0")
  })

  useEffect(() => {
    if (isAddCVT) refFirstCVT.current?.focus()
  }, [isAddCVT])

  useEffect(() => {
    if (isAddEVISA) refFirstEVISA.current?.focus()
  }, [isAddEVISA])

  useEffect(() => {
    if (editCVT !== false) setShowModalCVT(true)
  }, [editCVT, setShowModalCVT])

  useEffect(() => {
    if (editEVISA !== false) setShowModalEVISA(true)
  }, [editEVISA, setShowModalEVISA])

  const FormAdd = useCallback(({ onSubmit, setIsAdd, refFirst }) => {
    let initialValues = {
      title_vn: "",
      title_en: "",
      gia_ban: 0,
      gia_von: 0,
      vip_fee: 0,
      service_fee: 0
    }

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit
    })

    return (
      <tr className="align-top">
        <th scope="row" className="text-center">
          <Button onClick={() => setIsAdd(false)}>
            <i className="bx bx-x"></i>
          </Button>
        </th>
        <td>
          <Input
            type="text"
            className=""
            placeholder="Title VN"
            name="title_vn"
            innerRef={refFirst}
            value={formik.values?.title_vn || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.title_vn && formik.errors.title_vn ? true : false}
          />
          {formik.errors.title_vn ? <FormFeedback>{formik.errors.title_vn}</FormFeedback> : null}
        </td>
        <td>
          <Input
            type="text"
            className="form-control"
            placeholder="Title EN"
            name="title_en"
            value={formik.values?.title_en || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.title_en && formik.errors.title_en ? true : false}
          />
          {formik.errors.title_en ? <FormFeedback>{formik.errors.title_en}</FormFeedback> : null}
        </td>
        <td>
          <Input
            type="number"
            className="form-control"
            placeholder="Price"
            name="gia_ban"
            value={formik.values?.gia_ban || 0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.gia_ban && formik.errors.gia_ban ? true : false}
            step={1000}
            min={0}
          />
          {formik.errors.gia_ban ? <FormFeedback>{formik.errors.gia_ban}</FormFeedback> : null}
        </td>
        <td>
          <Input
            type="number"
            className="form-control"
            placeholder="Cost"
            name="gia_von"
            value={formik.values?.gia_von || 0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.gia_von && formik.errors.gia_von ? true : false}
            step={1000}
            min={0}
          />
          {formik.errors.gia_von ? <FormFeedback>{formik.errors.gia_von}</FormFeedback> : null}
        </td>
        <td>
          <Input
            type="number"
            className="form-control"
            placeholder="VIP Fee"
            name="vip_fee"
            value={formik.values?.vip_fee || 0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.vip_fee && formik.errors.vip_fee ? true : false}
            step={1000}
            min={0}
          />
          {formik.errors.vip_fee ? <FormFeedback>{formik.errors.vip_fee}</FormFeedback> : null}
        </td>
        <td>
          <Input
            type="number"
            className="form-control"
            placeholder="Service Fee"
            name="service_fee"
            value={formik.values?.service_fee || 0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.service_fee && formik.errors.service_fee ? true : false}
            step={1000}
            min={0}
          />
          {formik.errors.service_fee ? <FormFeedback>{formik.errors.service_fee}</FormFeedback> : null}
        </td>
        <td className="text-center">
          <Button color="success" onClick={formik.handleSubmit}>
            <i className="bx bxs-paper-plane"></i>
          </Button>
        </td>
      </tr>
    )
  }, [])

  const FormEdit = useCallback(({ onSubmit, urgent_model, edit, showModal, toggleModal }) => {
    let dataFormik = {
      initialValues: {},
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: onSubmit
    }

    if (edit !== false) {
      let values = urgent_model[edit]
      dataFormik.initialValues = values
    }

    const formik = useFormik(dataFormik)

    return (
      <>
        <Modal isOpen={showModal} backdrop="static" toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>Modal title</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Title VN</Label>
                  <Input
                    type="text"
                    className=""
                    placeholder="Title VN"
                    name="title_vn"
                    value={formik.values?.title_vn || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.title_vn && formik.errors.title_vn ? true : false}
                  />
                  {formik.errors.title_vn ? <FormFeedback>{formik.errors.title_vn}</FormFeedback> : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Title EN</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Title EN"
                    name="title_en"
                    value={formik.values?.title_en || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.title_en && formik.errors.title_en ? true : false}
                  />
                  {formik.errors.title_en ? <FormFeedback>{formik.errors.title_en}</FormFeedback> : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Price</Label>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    name="gia_ban"
                    value={formik.values?.gia_ban || 0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.gia_ban && formik.errors.gia_ban ? true : false}
                    step={1000}
                    min={0}
                  />
                  {formik.errors.gia_ban ? <FormFeedback>{formik.errors.gia_ban}</FormFeedback> : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Cost</Label>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Cost"
                    name="gia_von"
                    value={formik.values?.gia_von || 0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.gia_von && formik.errors.gia_von ? true : false}
                    step={1000}
                    min={0}
                  />
                  {formik.errors.gia_von ? <FormFeedback>{formik.errors.gia_von}</FormFeedback> : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>VIP Fee</Label>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="VIP Fee"
                    name="vip_fee"
                    value={formik.values?.vip_fee || 0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.vip_fee && formik.errors.vip_fee ? true : false}
                    step={1000}
                    min={0}
                  />
                  {formik.errors.vip_fee ? <FormFeedback>{formik.errors.vip_fee}</FormFeedback> : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Service Fee</Label>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Service Fee"
                    name="service_fee"
                    value={formik.values?.service_fee || 0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.service_fee && formik.errors.service_fee ? true : false}
                    step={1000}
                    min={0}
                  />
                  {formik.errors.service_fee ? <FormFeedback>{formik.errors.service_fee}</FormFeedback> : null}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={formik.handleSubmit}>
              <i className="bx bxs-paper-plane"></i> SUBMIT
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }, [])

  const TableHeader = useCallback(() => {
    return (
      <thead>
        <tr>
          <th className="text-center" style={{ width: "44px" }}>
            #
          </th>
          <th>Title VN</th>
          <th>Title EN</th>
          <th className="text-end" style={{ width: "150px" }}>
            Price
          </th>
          <th className="text-end" style={{ width: "150px" }}>
            Cost
          </th>
          <th className="text-end" style={{ width: "150px" }}>
            VIP Fee
          </th>
          <th className="text-end" style={{ width: "150px" }}>
            Service Fee
          </th>
          <th className="text-center" style={{ width: "44px" }}>
            Action
          </th>
        </tr>
      </thead>
    )
  }, [])

  const TableDatas = useCallback(({ datas, setEdit, handleDelete }) => {
    return (
      <>
        {datas?.length > 0 &&
          datas?.map((value, index) => {
            return (
              <tr key={index + 1}>
                <td className="text-center">{index + 1}</td>
                <td>{value.title_vn}</td>
                <td>{value.title_en}</td>
                <td className="text-end">{value.gia_ban}</td>
                <td className="text-end">{value.gia_von}</td>
                <td className="text-end">{value.vip_fee}</td>
                <td className="text-end">{value.service_fee}</td>
                <td className="text-center">
                  <ButtonGroup>
                    <Button color="warning" size="sm" onClick={() => setEdit(index)}>
                      <i className="bx bxs-pencil"></i>
                    </Button>
                    <Button color="danger" size="sm" onClick={() => handleDelete(index)}>
                      <i className="bx bx-x"></i>
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            )
          })}

        {(!datas || datas?.length <= 0) && (
          <tr>
            <td className="text-center" colSpan={50}>
              <p>Không có nội dung hiển thị</p>
            </td>
          </tr>
        )}
      </>
    )
  }, [])

  const Title = useCallback(({ title, isAdd, setIsAdd }) => {
    return (
      <h5>
        {title}{" "}
        {!isAdd && (
          <Link onClick={() => setIsAdd(true)}>
            <Badge color="success" pill>
              <i className="bx bx-plus-circle" /> ADD NEW
            </Badge>
          </Link>
        )}
      </h5>
    )
  }, [])

  return (
    <div>
      <CardTitle>Partner Configs</CardTitle>
      <Title title="URGENT MODEL CVT" isAdd={isAddCVT} setIsAdd={setIsAddCVT} />
      <Table className="align-middle">
        <TableHeader />
        <tbody>
          <TableDatas
            datas={data.urgent_model_CVT || []}
            setEdit={setEditCVT}
            handleDelete={index => {
              let urgent_model = []
              data.urgent_model_CVT.map((value, i) => {
                if (index != i) urgent_model.push(value)
              })
              setData({ ...data, urgent_model_CVT: urgent_model })
            }}
          />
          {isAddCVT && (
            <FormAdd
              setIsAdd={setIsAddCVT}
              refFirst={refFirstCVT}
              onSubmit={(values, actions) => {
                const dataOld = data.urgent_model_CVT
                let urgent_model = dataOld != undefined ? [...dataOld, values] : [values]
                setData({ ...data, urgent_model_CVT: urgent_model })
                actions.resetForm()
                refFirstCVT.current?.focus()
              }}
            />
          )}
        </tbody>
      </Table>
      <FormEdit
        urgent_model={data.urgent_model_CVT || []}
        edit={editCVT}
        showModal={showModalCVT}
        toggleModal={toggleModalCVT}
        onSubmit={(values, actions) => {
          let urgent_model = []
          data.urgent_model_CVT.map((value, i) => {
            if (editCVT == i) urgent_model.push(values)
            else urgent_model.push(value)
          })
          setData({ ...data, urgent_model_CVT: urgent_model })
          toggleModalCVT()
          actions.resetForm()
        }}
      />

      <Title title="URGENT MODEL EVISA" isAdd={isAddEVISA} setIsAdd={setIsAddEVISA} />
      <Table className="align-middle">
        <TableHeader />
        <tbody>
          <TableDatas
            datas={data?.urgent_model_EVISA}
            setEdit={setEditEVISA}
            handleDelete={index => {
              let urgent_model = []
              data.urgent_model_EVISA.map((value, i) => {
                if (index != i) urgent_model.push(value)
              })
              setData({ ...data, urgent_model_EVISA: urgent_model })
            }}
          />
          {isAddEVISA && (
            <FormAdd
              setIsAdd={setIsAddEVISA}
              refFirst={refFirstEVISA}
              onSubmit={(values, actions) => {
                const dataOld = data.urgent_model_EVISA
                let urgent_model = dataOld != undefined ? [...dataOld, values] : [values]
                setData({ ...data, urgent_model_EVISA: urgent_model })
                actions.resetForm()
                refFirstEVISA.current?.focus()
              }}
            />
          )}
        </tbody>
      </Table>

      <FormEdit
        urgent_model={data?.urgent_model_EVISA}
        edit={editEVISA}
        showModal={showModalEVISA}
        toggleModal={toggleModalEVISA}
        onSubmit={(values, actions) => {
          let urgent_model = []
          data.urgent_model_EVISA.map((value, i) => {
            if (editEVISA == i) urgent_model.push(values)
            else urgent_model.push(value)
          })
          setData({ ...data, urgent_model_EVISA: urgent_model })
          toggleModalEVISA()
          actions.resetForm()
        }}
      />
    </div>
  )
}
