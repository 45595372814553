import React, { useMemo, useState, useEffect, useRef, useCallback, useContext } from "react"
import { Container, Button } from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { ListResultBCAResult } from "services/"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgButtonDetail from "./AgCellRendering/AgButtonDetail"
import AgButtonDVC from "./AgCellRendering/AgButtonDvc"
// import AgButtonCancel from "./AgCellRendering/AgButtonCancel"
import AgText from "./AgCellRendering/AgText"
import AgTextUrgent from "./AgCellRendering/AgTextUrgent"

import ModalDetail from "./Modal"
// import ModalSubmitNew from "./ModalSubmitNew"

import moment from "moment"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

import { useLocation } from "react-router-dom"

import LoadingContext from "context/loading.context"

const ListResultBCA = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [item, setItem] = useState({})
  const [data, setData] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const [itemDetail, setItemDetail] = useState({})
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const { setLoading } = useContext(LoadingContext)

  const { state } = useLocation()

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
      setItem({})
    } else {
      setIsOpen(true)
    }
  }

  const toggleDetail = () => {
    if (isOpenDetail) {
      setIsOpenDetail(false)
    } else {
      setIsOpenDetail(true)
    }
  }

  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const converTrangThai = value => {
    switch (value) {
      case "- Đã trả kết quả":
        return t("Results returned")
      case "Đã trả kết quả":
        return t("Results returned")
      case "Đang được xử lý.":
        return t("Processing")
      case "- Đang được xử lý.":
        return t("Processing")
      case "":
        return t("Cancel")
      default:
        return value
    }
  }

  const fetchData = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }

    setLoading(true)
    const { data } = await ListResultBCAResult(payload)
    setLoading(false)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      let status = converTrangThai(item.trang_thai_ho_so)
      temp.push({
        ...item,
        createAt: moment(item.createAt).format("DD/MM/YYYY HH:mm:ss"),
        trang_thai_ho_so: status,
        ngay_nhap_canh: moment(item.ngay_nhap_canh).format("DD/MM/YYYY")
      })
    })
    setData(temp)
  }

  useEffect(() => {
    fetchData()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      headerName: t("Date Created"),
      field: "createAt",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left"
    },
    {
      headerName: t("Partner code"),
      field: "ma_doi_tac",
      pinned: "left",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Submit date"),
      field: "ngay_nop",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left"
    },
    {
      headerName: t("File number"),
      field: "so_ho_so",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Số công văn"),
      field: "so_cong_van",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Visistor number"),
      field: "so_khach",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Passport number"),
      field: "so_ho_chieu",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Number of days of stay"),
      field: "so_ngay_luu_tru",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Profile status"),
      field: "trang_thai_ho_so",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      cellRendererSelector: params => {
        const detail = {
          component: AgText
        }
        if (params.data) {
          return detail
        }
      }
    },
    {
      headerName: t("Detail"),
      field: "da_xu_ly_file",
      resizable: true,
      sortable: true,
      cellRendererSelector: params => {
        const detail = {
          component: AgButtonDetail,
        }
        if (params.data) {
          return detail
        }
      },
      cellRendererParams: {
        setItemDetail: setItemDetail,
        toggleDetail: toggleDetail,
        color: "primary",
      },
    },
    {
      headerName: t("Link DVC"),
      field: "link_detail",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRendererSelector: params => {
        const detail = {
          component: AgButtonDVC
        }
        if (params.data) {
          return detail
        }
      },
      cellRendererParams: {}
    },
    {
      headerName: t("Urgent"),
      field: "urgent",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const detail = {
          component: AgTextUrgent
        }
        if (params.data) {
          return detail
        }
      }
    },
    {
      headerName: t("Date of Entry"),
      field: "ngay_nhap_canh",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Visa receiving place"),
      field: "noi_nhan_thi_thuc",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Nationality"),
      field: "quoc_tich",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true
    },
    {
      headerName: t("Value of visa"),
      field: "gia_tri_thi_thuc",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true
    },
    {
      headerName: t("FAX"),
      field: "fax",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Customer segmentation"),
      field: "phan_tach_khach_hang",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Company Name"),
      field: "ten_cong_ty",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 450
    },
    // {
    //   headerName: t("Note"),
    //   field: "",
    //   resizable: true,
    //   floatingFilter: true,
    //   filter: "agTextColumnFilter",
    //   sortable: true,
    // },
    {
      headerName: t("Date of reception"),
      field: "ngay_tiep_nhan",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter"
    },
    {
      headerName: t("Date of success"),
      field: "ngay_tra_ket_qua",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Người xử lý"),
      field: "ma_nhan_vien"
    }
    // {
    //   headerName: t("Delete"),
    //   field: "trang_thai_ho_so",
    //   resizable: true,
    //   cellRendererSelector: params => {
    //     const cancel = {
    //       component: AgButtonCancel,
    //     }
    //     if (params.data) {
    //       return cancel
    //     }
    //   },
    //   cellRendererParams: {
    //     color: "danger",
    //     gridRef,
    //   },
    // },
  ]

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])

  const onGridReady = useCallback(params => {
    ListBCAResult().then(res => {
      let response = res.data
      let temp = []
      response.map((item, index) => {
        let status = converTrangThai(item.trang_thai_ho_so)
        item.createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
        item.trang_thai_ho_so = status
        item.ngay_nhap_canh = moment(item.ngay_nhap_canh).format("DD/MM/YYYY HH:mm:ss")
        temp.push(item)
      })
      setData(temp)
    })
  }, [])
  /**End ag grid config */

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <ModalDetail
          toggle={toggleDetail}
          isOpen={isOpenDetail}
          item={itemDetail}
        />
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Danh sách hồ sơ DVC")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              // onGridReady={onGridReady}
              onFirstDataRendered={params => {
                if (state && state.ma_ho_so) {
                  var filterComponent = params.api.getFilterInstance("so_ho_so")
                  filterComponent.setModel({
                    type: "contains",
                    filter: state.ma_ho_so
                  })
                  params.api.onFilterChanged()
                }
                params.api.autoSizeAllColumns()
              }}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListResultBCA
