import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"

//services
import { GetDashboardInfo } from "services"

const DashboardSaas = props => {
  let reports_sample = [
    {
      icon: "bx bx-copy-alt",
      title: "Passport",
      value: "1,452",
      period_value: "0",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period"
    },
    {
      icon: "bx bx-copy-alt",
      title: "Evisa",
      value: "1,452",
      period_value: "0",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period"
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$ 28,452",
      period_value: "0",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period"
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value: "$ 16.2",
      period_value: "0",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period"
    }
  ]

  const [reports, setReports] = useState([])
  const [total_nhanvien, setTotalNhanvien] = useState(0)
  const [total_doitac, setTotalDoitac] = useState(0)
  const [total_orders_evisa, setTotalOrdersEvisa] = useState(0)
  const [total_orders_passport, setTotalOrdersPassport] = useState(0)
  const [total_earning, setTotalEarning] = useState(0)
  const [total_earning_last_month, setTotalEarningLastMonth] = useState(0)
  const [earning_chart_data, setEarningChartData] = useState([])
  //meta title
  document.title = "Dashboard | quanly.visavietnam.biz"

  const calculator = (now, past) => {
    let badgeValue = Number(Number(now) - Number(past))

    let color = Number(now) > Number(past) ? "success" : "warning"

    return {
      badgeValue,
      color
    }
  }

  useEffect(() => {
    GetDashboardInfo()
      .then(result => {
        let data = result.data
        console.log(data)
        setTotalDoitac(data.total_doitac)
        setTotalNhanvien(data.total_nhanvien)
        setTotalOrdersEvisa(data.total_evisa)
        setTotalOrdersPassport(data.total_passport)
        setTotalEarning(data.total_now_revenue)
        setTotalEarningLastMonth(data.total_last_month_revenue)
        let chart_data = Array()
        data.revenue_by_month.map((item, index) => {
          chart_data.push(Number(item.revenue))
          // chart_data.push(parseInt(item.revenue).toLocaleString());
        })
        setEarningChartData(chart_data)
        reports_sample.map((item, index) => {
          switch (item.title) {
            case "Passport":
              item.value = data.total_passport_now
              item.badgeValue = calculator(data.total_passport_now, data.total_passport_last_month).badgeValue
              item.color = calculator(data.total_passport_now, data.total_passport_last_month).color
              item.period_value = data.total_passport_last_month
              break
            case "Evisa":
              item.value = data.total_evisa_order_now
              item.badgeValue = calculator(data.total_evisa_order_now, data.total_evisa_order_last_month).badgeValue
              item.color = calculator(data.total_evisa_order_now, data.total_evisa_order_last_month).color
              item.period_value = data.total_evisa_order_last_month
              break
            case "Revenue":
              item.value = data.total_now_revenue
              item.badgeValue = calculator(data.total_now_revenue, data.total_last_month_revenue).badgeValue
              item.color = calculator(data.total_now_revenue, data.total_last_month_revenue).color
              item.period_value = data.total_last_month_revenue
              break
            case "Average Price":
              item.value = data.total_now_average_price
              item.badgeValue = calculator(data.total_now_average_price, data.total_last_month_average_price).badgeValue
              item.color = calculator(data.total_now_average_price, data.total_last_month_average_price).color
              item.period_value = data.total_last_month_average_price
              break
            default:
              break
          }
          setReports(reports_sample)
        })
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Dashboards" breadcrumbItem="Saas" /> */}

          {/* Card User */}
          <CardUser
            nhanvien={total_nhanvien}
            doitac={total_doitac}
            orders_evisa={total_orders_evisa}
            orders_passport={total_orders_passport}
          />

          <Row>
            {/* welcome card */}
            {/* <CardWelcome /> */}

            <Col xl="12">
              <Row>
                {/*mimi widgets */}
                <MiniWidget reports={reports} />
              </Row>
            </Col>
          </Row>

          <Row>
            {/* earning */}
            <Earning
              dataColors='["--bs-primary"]'
              earning={total_earning}
              earning_last_month={total_earning_last_month}
              chart_data={earning_chart_data}
            />

            {/* sales anytics */}
            <SalesAnalytics dataColors='["--bs-primary", "--bs-success", "--bs-danger"]' />
          </Row>

          <Row>
            {/* total selling product */}
            <TotalSellingProduct />

            {/* tasks */}
            <Tasks />

            {/* chat box */}
            <ChatBox />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
