export const daisuquan = [
  {
    label: "Tổng lãnh sự quán Việt Nam tại B.tambang - Campuchia",
    value: "Tổng lãnh sự quán Việt Nam tại B.tambang - Campuchia"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Bombay - Ấn Độ",
    value: "Tổng lãnh sự quán Việt Nam tại Bombay - Ấn Độ"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại C.p.xom - Campuchia",
    value: "Tổng lãnh sự quán Việt Nam tại C.p.xom - Campuchia"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Côn Minh - Trung Quốc",
    value: "Tổng lãnh sự quán Việt Nam tại Côn Minh - Trung Quốc"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại DUBAI",
    value: "Tổng lãnh sự quán Việt Nam tại DUBAI"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Frankfurt - CHLB Đức",
    value: "Tổng lãnh sự quán Việt Nam tại Frankfurt - CHLB Đức"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Fukuoka-Nhật Bản",
    value: "Tổng lãnh sự quán Việt Nam tại Fukuoka-Nhật Bản"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Geneve-Thuỵ Sĩ",
    value: "Tổng lãnh sự quán Việt Nam tại Geneve-Thuỵ Sĩ"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Hồng Kông",
    value: "Tổng lãnh sự quán Việt Nam tại Hồng Kông"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Houston - Hoa Kỳ",
    value: "Tổng lãnh sự quán Việt Nam tại Houston - Hoa Kỳ"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Khon Kaen - Thái Lan",
    value: "Tổng lãnh sự quán Việt Nam tại Khon Kaen - Thái Lan"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Luông Pha Băng - Lào",
    value: "Tổng lãnh sự quán Việt Nam tại Luông Pha Băng - Lào"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Nam Ninh - Trung Quốc",
    value: "Tổng lãnh sự quán Việt Nam tại Nam Ninh - Trung Quốc"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Osaka-Nhật Bản",
    value: "Tổng lãnh sự quán Việt Nam tại Osaka-Nhật Bản"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Pắc Xế - Lào",
    value: "Tổng lãnh sự quán Việt Nam tại Pắc Xế - Lào"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Perth - Australia",
    value: "Tổng lãnh sự quán Việt Nam tại Perth - Australia"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Quảng Châu - Trung Quốc",
    value: "Tổng lãnh sự quán Việt Nam tại Quảng Châu - Trung Quốc"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại San Francisco - Hoa Kỳ",
    value: "Tổng lãnh sự quán Việt Nam tại San Francisco - Hoa Kỳ"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Savanakhet - Lào",
    value: "Tổng lãnh sự quán Việt Nam tại Savanakhet - Lào"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Sihanouk Ville - Campuchia",
    value: "Tổng lãnh sự quán Việt Nam tại Sihanouk Ville - Campuchia"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Sydney - Australia",
    value: "Tổng lãnh sự quán Việt Nam tại Sydney - Australia"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại CHLB Nga (Vladivostok)",
    value: "Tổng lãnh sự quán Việt Nam tại CHLB Nga (Vladivostok)"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Thượng Hải - Trung Quốc",
    value: "Tổng lãnh sự quán Việt Nam tại Thượng Hải - Trung Quốc"
  },
  {
    label: "Tổng lãnh sự quán Việt Nam tại Vancouver - Canada",
    value: "Tổng lãnh sự quán Việt Nam tại Vancouver - Canada"
  },
  {
    label: "Đại sứ quán Việt Nam Tại Slovakia",
    value: "Đại sứ quán Việt Nam Tại Slovakia"
  },
  {
    label: "Đại sứ quán Việt Nam tại A-rập Xê út",
    value: "Đại sứ quán Việt Nam tại A-rập Xê út"
  },
  {
    label: "Đại sứ quán Việt Nam tại Abu Dhabi - U.A.E",
    value: "Đại sứ quán Việt Nam tại Abu Dhabi - U.A.E"
  },
  {
    label: "Đại sứ quán Việt Nam tại Ai Cập",
    value: "Đại sứ quán Việt Nam tại Ai Cập"
  },
  {
    label: "Đại sứ quán Việt Nam tại An-giê-ri",
    value: "Đại sứ quán Việt Nam tại An-giê-ri"
  },
  {
    label: "Đại sứ quán Việt Nam tại Angola",
    value: "Đại sứ quán Việt Nam tại Angola"
  },
  {
    label: "Đại sứ quán Việt Nam tại Anh",
    value: "Đại sứ quán Việt Nam tại Anh"
  },
  {
    label: "Đại sự quán Việt Nam tại Ankara - Thổ Nhĩ Kỳ",
    value: "Đại sự quán Việt Nam tại Ankara - Thổ Nhĩ Kỳ"
  },
  {
    label: "Đại sứ quán Việt Nam tại Argentina",
    value: "Đại sứ quán Việt Nam tại Argentina"
  },
  {
    label: "Đại sứ quán Việt Nam tại Ba Lan",
    value: "Đại sứ quán Việt Nam tại Ba Lan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Bắc Kinh - Trung Quốc",
    value: "Đại sứ quán Việt Nam tại Bắc Kinh - Trung Quốc"
  },
  {
    label: "Đại sứ quán Việt Nam tại Bangladesh",
    value: "Đại sứ quán Việt Nam tại Bangladesh"
  },
  {
    label: "Đại sứ quán Việt Nam tại Belarus",
    value: "Đại sứ quán Việt Nam tại Belarus"
  },
  {
    label: "Đại sứ quán Việt Nam tại Berlin - CHLB Đức",
    value: "Đại sứ quán Việt Nam tại Berlin - CHLB Đức"
  },
  {
    label: "Đại sứ quán Việt Nam tại Bỉ",
    value: "Đại sứ quán Việt Nam tại Bỉ"
  },
  {
    label: "Đại sứ quán Việt Nam tại Brazil",
    value: "Đại sứ quán Việt Nam tại Brazil"
  },
  {
    label: "Đại sứ quán Việt Nam tại Brunei",
    value: "Đại sứ quán Việt Nam tại Brunei"
  },
  {
    label: "Đại sứ quán Việt Nam tại Bun-ga-ri",
    value: "Đại sứ quán Việt Nam tại Bun-ga-ri"
  },
  {
    label: "Đại sứ quán Việt Nam tại CHDCND Triều Tiên",
    value: "Đại sứ quán Việt Nam tại CHDCND Triều Tiên"
  },
  {
    label: "Đại sứ quán Việt Nam tại CHLB Đức - Văn phòng Bonn",
    value: "Đại sứ quán Việt Nam tại CHLB Đức - Văn phòng Bonn"
  },
  {
    label: "Đại sứ quán Việt Nam tại Campuchia",
    value: "Đại sứ quán Việt Nam tại Campuchia"
  },
  {
    label: "Đại sứ quán Việt Nam tại Canada",
    value: "Đại sứ quán Việt Nam tại Canada"
  },
  {
    label: "Đại sứ quán Việt Nam tại Canberra - Australia",
    value: "Đại sứ quán Việt Nam tại Canberra - Australia"
  },
  {
    label: "Đại sứ quán Việt Nam tại Cộng hoà Áo",
    value: "Đại sứ quán Việt Nam tại Cộng hoà Áo"
  },
  {
    label: "Đại sứ quán Việt Nam tại Cộng hoà Nam Phi",
    value: "Đại sứ quán Việt Nam tại Cộng hoà Nam Phi"
  },
  {
    label: "Đại sứ quán Việt Nam tại Cộng hoà Pháp",
    value: "Đại sứ quán Việt Nam tại Cộng hoà Pháp"
  },
  {
    label: "Đại sứ quán Việt Nam tại Cộng hoà Séc",
    value: "Đại sứ quán Việt Nam tại Cộng hoà Séc"
  },
  {
    label: "Đại sứ quán Việt Nam tại Cu Ba",
    value: "Đại sứ quán Việt Nam tại Cu Ba"
  },
  {
    label: "Đại sứ quán Việt Nam tại Đan Mạch",
    value: "Đại sứ quán Việt Nam tại Đan Mạch"
  },
  {
    label: "Đại sứ quán Việt Nam tại Hà Lan",
    value: "Đại sứ quán Việt Nam tại Hà Lan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Hàn Quốc",
    value: "Đại sứ quán Việt Nam tại Hàn Quốc"
  },
  {
    label: "Đại sứ quán Việt Nam tại Hung-ga-ri",
    value: "Đại sứ quán Việt Nam tại Hung-ga-ri"
  },
  {
    label: "Đại sứ quán Việt Nam tại Hy Lạp",
    value: "Đại sứ quán Việt Nam tại Hy Lạp"
  },
  {
    label: "Đại sứ quán Việt Nam tại I-rắc",
    value: "Đại sứ quán Việt Nam tại I-rắc"
  },
  {
    label: "Đại sứ quán Việt Nam tại I-ran",
    value: "Đại sứ quán Việt Nam tại I-ran"
  },
  {
    label: "Đại sứ quán Việt Nam tại I-ta-li-a",
    value: "Đại sứ quán Việt Nam tại I-ta-li-a"
  },
  {
    label: "Đại sứ quán Việt Nam tại Indonesia",
    value: "Đại sứ quán Việt Nam tại Indonesia"
  },
  {
    label: "Đại sứ quán Việt Nam tại Israel",
    value: "Đại sứ quán Việt Nam tại Israel"
  },
  {
    label: "Đại sứ quán Việt Nam tại Kazakhstan",
    value: "Đại sứ quán Việt Nam tại Kazakhstan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Kuwait",
    value: "Đại sứ quán Việt Nam tại Kuwait"
  },
  {
    label: "Đại sứ quán Việt Nam tại Lào",
    value: "Đại sứ quán Việt Nam tại Lào"
  },
  {
    label: "Đại sứ quán Việt Nam tại Li Bi",
    value: "Đại sứ quán Việt Nam tại Li Bi"
  },
  {
    label: "Đại sứ quán Việt Nam tại Malaysia",
    value: "Đại sứ quán Việt Nam tại Malaysia"
  },
  {
    label: "Đại sứ quán Việt Nam tại Mexico",
    value: "Đại sứ quán Việt Nam tại Mexico"
  },
  {
    label: "Đại sứ quán Việt Nam tại Mông Cổ",
    value: "Đại sứ quán Việt Nam tại Mông Cổ"
  },
  {
    label: "Đại sứ quán Việt Nam tại Mozambique",
    value: "Đại sứ quán Việt Nam tại Mozambique"
  },
  {
    label: "Đại sứ quán Việt Nam tại Myanmar",
    value: "Đại sứ quán Việt Nam tại Myanmar"
  },
  {
    label: "Đại sứ quán Việt Nam tại Na-uy",
    value: "Đại sứ quán Việt Nam tại Na-uy"
  },
  {
    label: "Đại sứ quán Việt Nam tại New Zealand",
    value: "Đại sứ quán Việt Nam tại New Zealand"
  },
  {
    label: "Đại sứ quán Việt Nam tại Newdelli - Ấn Độ",
    value: "Đại sứ quán Việt Nam tại Newdelli - Ấn Độ"
  },
  {
    label: "Đại sứ quán Việt Nam tại Nhật Bản",
    value: "Đại sứ quán Việt Nam tại Nhật Bản"
  },
  {
    label: "Đại sứ quán Việt Nam tại Nigeria",
    value: "Đại sứ quán Việt Nam tại Nigeria"
  },
  {
    label: "Đại sứ quán Việt Nam tại Pakistan",
    value: "Đại sứ quán Việt Nam tại Pakistan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Panama",
    value: "Đại sứ quán Việt Nam tại Panama"
  },
  {
    label: "Đại sứ quán Việt Nam tại Phần Lan",
    value: "Đại sứ quán Việt Nam tại Phần Lan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Phi-lip-pin",
    value: "Đại sứ quán Việt Nam tại Phi-lip-pin"
  },
  {
    label: "Đại sứ quán Việt Nam tại Qatar",
    value: "Đại sứ quán Việt Nam tại Qatar"
  },
  {
    label: "Đại sứ quán Việt Nam tại Ru-ma-ni",
    value: "Đại sứ quán Việt Nam tại Ru-ma-ni"
  },
  {
    label: "Đại sứ quán Việt Nam tại Singapore",
    value: "Đại sứ quán Việt Nam tại Singapore"
  },
  {
    label: "Đại sứ quán Việt Nam tại Tan-za-ni-a",
    value: "Đại sứ quán Việt Nam tại Tan-za-ni-a"
  },
  {
    label: "Đại sứ quán Việt Nam tại Tây Ban Nha",
    value: "Đại sứ quán Việt Nam tại Tây Ban Nha"
  },
  {
    label: "Đại sứ quán Việt Nam tại Thái Lan",
    value: "Đại sứ quán Việt Nam tại Thái Lan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Thuỵ Sĩ",
    value: "Đại sứ quán Việt Nam tại Thuỵ Sĩ"
  },
  {
    label: "Đại sứ quán Việt Nam tại Ucraina",
    value: "Đại sứ quán Việt Nam tại Ucraina"
  },
  {
    label: "Đại sứ quán Việt Nam tại Uzrbekistan",
    value: "Đại sứ quán Việt Nam tại Uzrbekistan"
  },
  {
    label: "Đại sứ quán Việt Nam tại Venezuela",
    value: "Đại sứ quán Việt Nam tại Venezuela"
  },
  {
    label: "Đại sứ quán Việt Nam tại Vương quốc Thuỵ Điển",
    value: "Đại sứ quán Việt Nam tại Vương quốc Thuỵ Điển"
  },
  {
    label: "Đại sứ quán Việt Nam tại Washington -  Hoa Kỳ",
    value: "Đại sứ quán Việt Nam tại Washington -  Hoa Kỳ"
  },
  {
    label: "Đại sứ quán Việt Nam tại CHLB Nga ",
    value: "Đại sứ quán Việt Nam tại CHLB Nga "
  },
  {
    label: "Đại sứ quán Việt Nam tại Ma rốc",
    value: "Đại sứ quán Việt Nam tại Ma rốc"
  },
  {
    label: "Đại sứ quán Việt Nam tại Srilanka",
    value: "Đại sứ quán Việt Nam tại Srilanka"
  },
  {
    label: "Lãnh sự quán Việt Nam tại Lebanon",
    value: "Lãnh sự quán Việt Nam tại Lebanon"
  },
  {
    label: "Lãnh sự quán Việt Nam tại New York - Hoa Kỳ",
    value: "Lãnh sự quán Việt Nam tại New York - Hoa Kỳ"
  }
]
