import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import DashboardSaas from "../pages/Dashboard-saas/index"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//SubmitProfile
import SubmitProfile from "../pages/SubmitProfile"

//SentList
import SentList from "../pages/SentList"

//ListChina Data
import ListChinaData from "../pages/ListChinaData"

//List BCA Data
import ListBCAResult from "../pages/ResultBcaList"

//Partner
import CreateNewParter from "../pages/Partner/Create"
import ListParter from "../pages/Partner/List"
import UpdatePartner from "../pages/Partner/Edit"

//Mindee
import Mindee from "../pages/Mindee"

//Proxies
import Proxies from "../pages/Proxies"

//Workers
import Worker from "../pages/Worker"

//Evisa
import ListEvisa from "../pages/Evisa/List"
import EvisaFakeInfo from "pages/Evisa/FakeInfo"

//Passport
import ListPassport from "../pages/Passport/List"
import ListPassportVerified from "../pages/Passport/Verified"

// ResultBCA
import ListResultBCA from "../pages/ResultBCA/List"


//Tool check DVC
import ToolCheckDvc from "pages/Tools/Dvc"

//Tool check EVISA
import ToolCheckEvisa from "pages/Tools/Evisa"

import ToolSoftware from "pages/Tools/Software"

//Result bca list
import DetailResultBca from "pages/ResultBCA/Detail"

//Statistical analysis doi tac
import Statistical from "pages/Statistical"

const authProtectedRoutes = [
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/dashboard", component: <DashboardSaas /> },
  { path: "/proxies", component: <Proxies /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/partner/list", component: <ListParter /> },
  { path: "/partner/create", component: <CreateNewParter /> },
  { path: "/partner/edit/:id", component: <UpdatePartner /> },
  { path: "/mindee", component: <Mindee /> },
  { path: "/worker", component: <Worker /> },
  { path: "/evisa/list", component: <ListEvisa /> },
  { path: "/passport/list", component: <ListPassport /> },
  { path: "/passport/verified/list", component: <ListPassportVerified /> },
  { path: "/result-bca/list", component: <ListResultBCA /> },
  { path: "/evisa/fake-info", component: <EvisaFakeInfo /> },

  { path: "/tool/dvc", component: <ToolCheckDvc /> },
  { path: "/tool/evisa", component: <ToolCheckEvisa /> },
  { path: "/tool/software", component: <ToolSoftware /> },

  { path: "/result-bca/detail/:ma_ho_so", component: <DetailResultBca/>},
  { path: "/thong-ke", component: <Statistical />},
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "*", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> }
]

export { authProtectedRoutes, publicRoutes }
