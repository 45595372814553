import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { Row, Col, Form, CardTitle } from "reactstrap"
import { Input, DatePicker, Select } from "../../../../helpers/Form/"

//import option
import quoctich from "../../Option/quoctich.json"

export default function ContentStep1({ onSubmit, refFormInfo }) {
  const validationSchema = Yup.object({
    username: Yup.string().required("Please Enter Username"),
    password: Yup.string().required("Please Enter Password"),
    fullname: Yup.string().required("Please Enter Full name"),
    email: Yup.string().email("Email address is not valid"),
    dob: Yup.string().required("Please Enter Dob"),
    gender: Yup.object().required("Please select Sex"),
    country: Yup.object().required("Please select Country"),
    phone: Yup.string(),
    address: Yup.string()
  })

  const initialValues = {
    username: "",
    password: "",
    fullname: "",
    email: "",
    dob: "",
    gender: "",
    country: "",
    phone: "",
    address: ""
  }

  const gender = [
    { value: false, label: "Female" },
    { value: true, label: "Male" }
  ]

  return (
    <div>
      <CardTitle>Partner infomation</CardTitle>
      <p className="card-title-desc">Fill all information below</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={refFormInfo}
      >
        {formikProps => {
          const { values, handleChange, handleBlur, handleSubmit, errors } = formikProps
          console.log(values)
          return (
            <Form>
              <Row>
                <Col md={6}>
                  <Input label="Username" id="username" name="username" required={true} />
                </Col>
                <Col md={6}>
                  <Input label="Password" id="password" name="password" required={true} type="password" />
                </Col>
                <Col md={4}>
                  <Input label="Full name" id="fullname" name="fullname" required={true} />
                </Col>
                <Col md={4}>
                  <Input label="Email" id="email" name="email" type="email" />
                </Col>
                <Col md={4}>
                  <Input label="Phone number" id="phone" name="phone" />
                </Col>
                <Col md={4}>
                  <DatePicker label="Dob" id="dob" name="dob" required={true} />
                </Col>
                <Col md={4}>
                  <Select label="Sex" id="gender" name="gender" options={gender} required={true} />
                </Col>
                <Col md={4}>
                  <Select label="Country" id="country" name="country" options={quoctich} required={true} />
                </Col>
                <Col md={12}>
                  <Input label="Address" id="address" name="address" type="textarea" />
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
