import React, { forwardRef } from "react"

const AgActive = forwardRef((props, ref) => {
  return (
    <>
      <span>
        <i
          className={
            props.data.status === true ? "fas fa-check" : "" || props.data.status === false ? "fas fa-times" : ""
          }
          style={{ color: props.data.status === true ? "#1eff00" : "#ff0000" }}
        />{" "}
        {props.data.status}
      </span>
    </>
  )
})

AgActive.displayName = "AgActive"

export default AgActive
