export const khac = [
  {
    label: "Cục lãnh sự",
    value: "Cục lãnh sự"
  },
  {
    label: "Cục quản lý XNC",
    value: "Cục quản lý XNC"
  },
  {
    label: "Cục quản lý XNC Tại Đà Nẵng",
    value: "Cục quản lý XNC Tại Đà Nẵng"
  },
  {
    label: "Cục quản lý XNC Tại TPHCM",
    value: "Cục quản lý XNC Tại TPHCM"
  },
  {
    label: "Cục quản lý XNC Việt Nam",
    value: "Cục quản lý XNC Việt Nam"
  },
  {
    label: "Sở Ngoại Vụ",
    value: "Sở Ngoại Vụ"
  },
  {
    label: "Văn phòng Kinh tế-Văn hoá Việt Nam tại Đài Bắc",
    value: "Văn phòng Kinh tế-Văn hoá Việt Nam tại Đài Bắc"
  },
  {
    label: "XNC An Giang",
    value: "XNC An Giang"
  },
  {
    label: "XNC Bà Rịa Vũng Tàu",
    value: "XNC Bà Rịa Vũng Tàu"
  },
  {
    label: "XNC Bắc Giang",
    value: "XNC Bắc Giang"
  },
  {
    label: "XNC Bắc Kạn",
    value: "XNC Bắc Kạn"
  },
  {
    label: "XNC Bạc Liêu",
    value: "XNC Bạc Liêu"
  },
  {
    label: "XNC Bắc Ninh",
    value: "XNC Bắc Ninh"
  },
  {
    label: "XNC Bến Tre",
    value: "XNC Bến Tre"
  },
  {
    label: "XNC Bình Định",
    value: "XNC Bình Định"
  },
  {
    label: "XNC Bình Dương",
    value: "XNC Bình Dương"
  },
  {
    label: "XNC Bình Phước",
    value: "XNC Bình Phước"
  },
  {
    label: "XNC Bình Thuận",
    value: "XNC Bình Thuận"
  },
  {
    label: "XNC Cà Mau",
    value: "XNC Cà Mau"
  },
  {
    label: "XNC Cần Thơ",
    value: "XNC Cần Thơ"
  },
  {
    label: "XNC Cao Bằng",
    value: "XNC Cao Bằng"
  },
  {
    label: "XNC Đà Nẵng",
    value: "XNC Đà Nẵng"
  },
  {
    label: "XNC Đắk Lắk",
    value: "XNC Đắk Lắk"
  },
  {
    label: "XNC Đắk Nông",
    value: "XNC Đắk Nông"
  },
  {
    label: "XNC Điện Biên",
    value: "XNC Điện Biên"
  },
  {
    label: "XNC Đồng Nai",
    value: "XNC Đồng Nai"
  },
  {
    label: "XNC Đồng Tháp",
    value: "XNC Đồng Tháp"
  },
  {
    label: "XNC Gia Lai",
    value: "XNC Gia Lai"
  },
  {
    label: "XNC Hà Giang",
    value: "XNC Hà Giang"
  },
  {
    label: "XNC Hà Nam",
    value: "XNC Hà Nam"
  },
  {
    label: "XNC Hà Nội",
    value: "XNC Hà Nội"
  },
  {
    label: "XNC Hà Tây",
    value: "XNC Hà Tây"
  },
  {
    label: "XNC Hà Tĩnh",
    value: "XNC Hà Tĩnh"
  },
  {
    label: "XNC Hải Dương",
    value: "XNC Hải Dương"
  },
  {
    label: "XNC Hải Phòng",
    value: "XNC Hải Phòng"
  },
  {
    label: "XNC Hậu Giang",
    value: "XNC Hậu Giang"
  },
  {
    label: "XNC Hoà Bình",
    value: "XNC Hoà Bình"
  },
  {
    label: "XNC Hưng Yên",
    value: "XNC Hưng Yên"
  },
  {
    label: "XNC Khánh Hoà",
    value: "XNC Khánh Hoà"
  },
  {
    label: "XNC Kiên Giang",
    value: "XNC Kiên Giang"
  },
  {
    label: "XNC Kon Tum",
    value: "XNC Kon Tum"
  },
  {
    label: "XNC Lai Châu",
    value: "XNC Lai Châu"
  },
  {
    label: "XNC Lâm Đồng",
    value: "XNC Lâm Đồng"
  },
  {
    label: "XNC Lạng Sơn",
    value: "XNC Lạng Sơn"
  },
  {
    label: "XNC Lào Cai",
    value: "XNC Lào Cai"
  },
  {
    label: "XNC Long An",
    value: "XNC Long An"
  },
  {
    label: "XNC Nam Định",
    value: "XNC Nam Định"
  },
  {
    label: "XNC Nghệ An",
    value: "XNC Nghệ An"
  },
  {
    label: "XNC Ninh Bình",
    value: "XNC Ninh Bình"
  },
  {
    label: "XNC Ninh Thuận",
    value: "XNC Ninh Thuận"
  },
  {
    label: "XNC Phú Thọ",
    value: "XNC Phú Thọ"
  },
  {
    label: "XNC Phú Yên",
    value: "XNC Phú Yên"
  },
  {
    label: "XNC Quảng Bình",
    value: "XNC Quảng Bình"
  },
  {
    label: "XNC Quảng Nam",
    value: "XNC Quảng Nam"
  },
  {
    label: "XNC Quảng Ngãi",
    value: "XNC Quảng Ngãi"
  },
  {
    label: "XNC Quảng Ninh",
    value: "XNC Quảng Ninh"
  },
  {
    label: "XNC Quảng Trị",
    value: "XNC Quảng Trị"
  },
  {
    label: "XNC Sóc Trăng",
    value: "XNC Sóc Trăng"
  },
  {
    label: "XNC Sơn La",
    value: "XNC Sơn La"
  },
  {
    label: "XNC Tây Ninh",
    value: "XNC Tây Ninh"
  },
  {
    label: "XNC Thái Bình",
    value: "XNC Thái Bình"
  },
  {
    label: "XNC Thái Nguyên",
    value: "XNC Thái Nguyên"
  },
  {
    label: "XNC Thanh Hoá",
    value: "XNC Thanh Hoá"
  },
  {
    label: "XNC Thành phố Hồ Chí Minh",
    value: "XNC Thành phố Hồ Chí Minh"
  },
  {
    label: "XNC Thừa Thiên Huế",
    value: "XNC Thừa Thiên Huế"
  },
  {
    label: "XNC Tiền Giang",
    value: "XNC Tiền Giang"
  },
  {
    label: "XNC Trà Vinh",
    value: "XNC Trà Vinh"
  },
  {
    label: "XNC Tuyên Quang",
    value: "XNC Tuyên Quang"
  },
  {
    label: "XNC Vĩnh Long",
    value: "XNC Vĩnh Long"
  },
  {
    label: "XNC Vĩnh Phúc",
    value: "XNC Vĩnh Phúc"
  },
  {
    label: "XNC Yên Bái",
    value: "XNC Yên Bái"
  },
  {
    label: "Bộ Ngoại Giao",
    value: "Bộ Ngoại Giao"
  }
]
