const marchWalletData = {
  chartdata: [76, 67, 61],
  availablebalance: 6134.39,
  income: 2632.46,
  Expense: 924.38,
  Ethereum: {
    ETH: 4.5701,
    Dollar: 1123.64
  },
  Bitcoin: {
    BTC: 0.4412,
    Dollar: 4025.32
  },
  Litecoin: {
    LTC: 35.3811,
    Dollar: 2263.09
  }
}

const febWalletData = {
  chartdata: [65, 56, 47],
  availablebalance: 5296.39,
  income: 4126.12,
  Expense: 568.49,
  Ethereum: {
    ETH: 5.6781,
    Dollar: 958.52
  },
  Bitcoin: {
    BTC: 1.5269,
    Dollar: 5239.16
  },
  Litecoin: {
    LTC: 49.5987,
    Dollar: 3698.13
  }
}

const janWalletData = {
  chartdata: [84, 53, 49],
  availablebalance: 9575.39,
  income: 5749.46,
  Expense: 1698.38,
  Ethereum: {
    ETH: 1.5236,
    Dollar: 1123.64
  },
  Bitcoin: {
    BTC: 0.4412,
    Dollar: 2968.29
  },
  Litecoin: {
    LTC: 17.4169,
    Dollar: 1169.47
  }
}

const decWalletData = {
  chartdata: [72, 67, 51],
  availablebalance: 6134.39,
  income: 2698.46,
  Expense: 845.38,
  Ethereum: {
    ETH: 7.5698,
    Dollar: 3657.19
  },
  Bitcoin: {
    BTC: 2.8527,
    Dollar: 7459.62
  },
  Litecoin: {
    LTC: 43.4985,
    Dollar: 4789.93
  }
}

export { marchWalletData, febWalletData, janWalletData, decWalletData }
