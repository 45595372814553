export const cang = [
  {
    label: "Cảng Phước Thái",
    value: "Cảng Phước Thái"
  },
  {
    label: "Cảng Quy Nhơn",
    value: "Cảng Quy Nhơn"
  },
  {
    label: "Cảng Sa Kỳ",
    value: "Cảng Sa Kỳ"
  },
  {
    label: "Cảng Thanh Hoá",
    value: "Cảng Thanh Hoá"
  },
  {
    label: "Cảng Thị Nại",
    value: "Cảng Thị Nại"
  },
  {
    label: "Cảng Thuận An",
    value: "Cảng Thuận An"
  },
  {
    label: "Cảng Tp.Hồ Chí Minh",
    value: "Cảng Tp.Hồ Chí Minh"
  },
  {
    label: "Cảng Vạn Gia",
    value: "Cảng Vạn Gia"
  },
  {
    label: "Cảng Vĩnh Long",
    value: "Cảng Vĩnh Long"
  },
  {
    label: "Cảng Vũng Áng",
    value: "Cảng Vũng Áng"
  },
  {
    label: "Cảng Vũng Rô",
    value: "Cảng Vũng Rô"
  },
  {
    label: "Cảng Vũng Tàu",
    value: "Cảng Vũng Tàu"
  },
  {
    label: "Cảng Xuân Hải",
    value: "Cảng Xuân Hải"
  },
  {
    label: "Cảng đường sông Vĩnh Xương An Giang",
    value: "Cảng đường sông Vĩnh Xương An Giang"
  },
  {
    label: "Cảng hàng không Phù Cát",
    value: "Cảng hàng không Phù Cát"
  },
  {
    label: "Cảng Ba Ngòi",
    value: "Cảng Ba Ngòi"
  },
  {
    label: "Cảng Bến Lức",
    value: "Cảng Bến Lức"
  },
  {
    label: "Cảng Bến Thuỷ",
    value: "Cảng Bến Thuỷ"
  },
  {
    label: "Cảng Bình Định",
    value: "Cảng Bình Định"
  },
  {
    label: "Cảng Bình Trị",
    value: "Cảng Bình Trị"
  },
  {
    label: "Cảng Cẩm Phả",
    value: "Cảng Cẩm Phả"
  },
  {
    label: "Cảng Cần Thơ",
    value: "Cảng Cần Thơ"
  },
  {
    label: "Cảng Chân Mây",
    value: "Cảng Chân Mây"
  },
  {
    label: "Cảng Cửa Lò",
    value: "Cảng Cửa Lò"
  },
  {
    label: "Cảng Cửa Ông",
    value: "Cảng Cửa Ông"
  },
  {
    label: "Cảng Đà Nẵng",
    value: "Cảng Đà Nẵng"
  },
  {
    label: "Cảng Đầm Môn",
    value: "Cảng Đầm Môn"
  },
  {
    label: "Cảng Diêm Điền",
    value: "Cảng Diêm Điền"
  },
  {
    label: "Cảng Đồng Nai",
    value: "Cảng Đồng Nai"
  },
  {
    label: "Cảng Đồng Tháp",
    value: "Cảng Đồng Tháp"
  },
  {
    label: "Cảng Dung Quất",
    value: "Cảng Dung Quất"
  },
  {
    label: "Cảng Gianh",
    value: "Cảng Gianh"
  },
  {
    label: "Cảng Hải Phòng",
    value: "Cảng Hải Phòng"
  },
  {
    label: "Cảng Hòn Chông",
    value: "Cảng Hòn Chông"
  },
  {
    label: "Cảng Hòn Gai",
    value: "Cảng Hòn Gai"
  },
  {
    label: "Cảng Kỳ Hà",
    value: "Cảng Kỳ Hà"
  },
  {
    label: "Cảng Liên Hương",
    value: "Cảng Liên Hương"
  },
  {
    label: "Cảng Mỹ Tho",
    value: "Cảng Mỹ Tho"
  },
  {
    label: "Cảng Mỹ Thới",
    value: "Cảng Mỹ Thới"
  },
  {
    label: "Cảng Năm Căn",
    value: "Cảng Năm Căn"
  },
  {
    label: "Cảng Nha Trang",
    value: "Cảng Nha Trang"
  },
  {
    label: "Cảng Phú Quốc",
    value: "Cảng Phú Quốc"
  },
  {
    label: "Cảng Phú Quý",
    value: "Cảng Phú Quý"
  }
]
