import React from "react"
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"

const PartnerChargeModal = props => {
  const { isOpen, toggle, setNewUsername, cloneNewUser } = props

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        toggle={toggle}
        className="modal-dialog modal-sm"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>New Username</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <Input
                name="username"
                onChange={e => {
                  let value = e.target.value
                  if (value !== undefined && value !== "") {
                    setNewUsername(value)
                  }
                }}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                toggle()
                cloneNewUser()
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default PartnerChargeModal
