import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Container
} from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { Link } from "react-router-dom"
import { CreateNewMindee, GetMindeeList, UpdateMindeeKey } from "services/"
import PropTypes from "prop-types"

import * as Yup from "yup"
import { useFormik } from "formik"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgAction from "./AgCellRendering/AgAction"
import AgActive from "./AgCellRendering/AgActive"
import AgKey from "./AgCellRendering/AgKey"

import moment from "moment"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

// Loading
import LoadingContext from "context/loading.context"

const Worker = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState([])

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [mindee, setMindee] = useState(null)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const { setLoading } = useContext(LoadingContext)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: (mindee && mindee.id) || "",
      email: (mindee && mindee.email) || "",
      password: (mindee && mindee.password) || "",
      quantity: (mindee && mindee.quantity) || 250,
      key: (mindee && mindee.key) || "",
      expDate: (mindee && mindee.expDate) || "",
      status: (mindee && mindee.status) || false
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Email"),
      password: Yup.string().required("Please Enter Password"),
      quantity: Yup.number().required("Please Enter Total").min(100),
      key: Yup.string().required("Please Enter Mindee Key")
      // expDate: Yup.date().required(),
    }),
    onSubmit: values => {
      setIsSuccess(false)
      setIsError(false)
      setMessage("")
      if (isEdit) {
        let mindee_raw = mindee
        let check_onchange = true
        values.status = values.status === "Yes" ? true : false
        mindee_raw.status = mindee.status === "Yes" ? true : false
        console.log(values)
        console.log(mindee_raw)
        if (
          mindee_raw.email === values.email &&
          mindee_raw.password === values.password &&
          mindee_raw.quantity === values.quantity &&
          mindee_raw.expDate === values.expDate &&
          mindee_raw.status === values.status
        ) {
          check_onchange = false
        }
        if (check_onchange === true) {
          UpdateMindeeKey(values)
            .then(result => {
              setIsSuccess(true)
              setMessage("Update successfully")
              setIsEdit(false)
              // setTimeout(() => {
              //   validation.resetForm();
              //   toggle()
              // }, [3000])
              validation.resetForm()
              toggle()
            })
            .catch(err => {
              setIsSuccess(false)
              setIsError(true)
              setMessage(err.response?.data?.msg)
            })
        } else {
          setIsEdit(false)
          toggle()
        }
      } else {
        values.status = values.status ? true : false
        CreateNewMindee(values)
          .then(res => {
            setIsSuccess(true)
            setMessage("Create successfully")
            // setTimeout(() => {
            //   validation.resetForm();
            //   toggle()
            // }, [3000])
            validation.resetForm()
            toggle()
          })
          .catch(err => {
            setIsSuccess(false)
            setIsError(true)
            setMessage(err.response?.data?.msg)
          })
      }
    }
  })

  const toggleViewModal = () => setModal1(!modal1)

  const toggle = () => {
    if (modal) {
      setModal(false)
      setMindee(null)
    } else {
      setModal(true)
    }
  }

  const handleEdit = arg => {
    const mindee = arg
    setMindee({
      id: mindee._id,
      email: mindee.email,
      password: mindee.password,
      quantity: mindee.quantity,
      key: mindee.key,
      expDate: mindee.expDate,
      status: mindee.status === true ? "Yes" : "No"
    })
    setIsEdit(true)
    toggle()
  }

  const handleAdd = () => {
    setIsEdit(false)
    toggle()
  }

  const onClickDelete = order => {
    setMindee(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id))
      setDeleteModal(false)
    }
  }

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  let gridStyle = { minHeight: 750, marginTop: 10, width: "100%" }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const fetchData = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    const { data } = await GetMindeeList(payload)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      // let country = quoctich.find(element => element.value == item.country);
      temp.push({
        ...item,
        expDate: moment(item.expDate).format("DD/MM/YYYY"),
        action_edit: () => handleEdit(item)
      })
    })

    // Deplay loading
    if (data.length > 100) {
      setLoading(false)
      setData(temp)
    } else {
      setTimeout(() => {
        setLoading(false)
        setData(temp)
      }, 500)
    }
  }

  useEffect(() => {
    fetchData()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Email"),
      field: "email",
      cellStyle: { textAlign: "left" }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Password"),
      field: "password"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Total"),
      field: "quantity"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Key"),
      field: "key",
      type: "width_default",
      cellRendererSelector: params => {
        if (params.data) return { component: AgKey }
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("ExpDate"),
      field: "expDate"
    },
    {
      headerName: t("ACTIVE"),
      field: "status",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgActive }
      }
    },
    {
      headerName: t("ACTION"),
      field: "",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgAction }
      }
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])

  const columnTypes = useMemo(() => {
    return {
      width_default: {
        width: "250"
      }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit Key" : "Add New Key"}
          </ModalHeader>
          <ModalBody>
            {isError ? <Alert color="danger">{message}</Alert> : ""}
            {isSuccess ? <Alert color="success">{message}</Alert> : ""}
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      type="text"
                      placeholder="Insert email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={validation.touched.email && validation.errors.email ? true : false}
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      type="text"
                      placeholder="Insert Password"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={validation.touched.password && validation.errors.password ? true : false}
                    />
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Total</Label>
                    <Input
                      name="quantity"
                      type="number"
                      placeholder="Insert Total Amount"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.quantity}
                      invalid={validation.touched.quantity && validation.errors.quantity ? true : false}
                    />
                    {validation.touched.quantity && validation.errors.quantity ? (
                      <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Key</Label>
                    <Input
                      name="key"
                      type="text"
                      placeholder="Insert Key"
                      disabled={isEdit}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.key || ""}
                      invalid={validation.touched.key && validation.errors.key ? true : false}
                    />
                    {validation.touched.key && validation.errors.key ? (
                      <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">ExpDate</Label>
                    {/* <Input
                        name="expDate"
                        type="date"
                        placeholder="Insert expDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.expDate || ""
                        }
                        invalid={
                          validation.touched.expDate && validation.errors.expDate ? true : false
                        }
                      /> */}
                    <DatePicker
                      name="expDate"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className={
                        "form-control" +
                        (validation.touched.expDate && validation.errors.expDate ? " border-danger" : "")
                      }
                      selected={validation.values.expDate && new Date(validation.values.expDate)}
                      onChange={val => {
                        validation.setFieldValue("expDate", val === null ? "" : val)
                      }}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.expDate && validation.errors.expDate ? (
                      <FormFeedback type="invalid">{validation.errors.expDate}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Active</Label>
                    <Input
                      name="status"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.status || ""}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </Input>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={isSuccess}>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Mindee")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
              <Link to="/partner/create" color="success" className="btn-rounded mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1" />
                {t("Add New Key")}
              </Link>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              columnTypes={columnTypes}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

Worker.propTypes = {
  preGlobalFilteredRows: PropTypes.any
}

export default Worker
