export const cuakhau = [
  {
    label: "Cửa khẩu Bờ Y",
    value: "Cửa khẩu Bờ Y"
  },
  {
    label: "Cửa khẩu Cầu Treo",
    value: "Cửa khẩu Cầu Treo"
  },
  {
    label: "Cửa khẩu Cha Lo",
    value: "Cửa khẩu Cha Lo"
  },
  {
    label: "Cửa khẩu Đồng Đăng",
    value: "Cửa khẩu Đồng Đăng"
  },
  {
    label: "Cửa khẩu Hà Tiên",
    value: "Cửa khẩu Hà Tiên"
  },
  {
    label: "Cửa khẩu Hà Tiên",
    value: "Cửa khẩu Hà Tiên"
  },
  {
    label: "Cửa khẩu Hoa Lư",
    value: "Cửa khẩu Hoa Lư"
  },
  {
    label: "Cửa khẩu Hữu Nghị",
    value: "Cửa khẩu Hữu Nghị"
  },
  {
    label: "Cửa khẩu Lao Bảo",
    value: "Cửa khẩu Lao Bảo"
  },
  {
    label: "Cửa khẩu Lào Cai",
    value: "Cửa khẩu Lào Cai"
  },
  {
    label: "Cửa khẩu Mộc Bài",
    value: "Cửa khẩu Mộc Bài"
  },
  {
    label: "Cửa khẩu Móng Cái",
    value: "Cửa khẩu Móng Cái"
  },
  {
    label: "Cửa khẩu Na Mèo",
    value: "Cửa khẩu Na Mèo"
  },
  {
    label: "Cửa khẩu Nậm Cắn",
    value: "Cửa khẩu Nậm Cắn"
  },
  {
    label: "Cửa khẩu Sông Tiền",
    value: "Cửa khẩu Sông Tiền"
  },
  {
    label: "Cửa khẩu Tà Lùng",
    value: "Cửa khẩu Tà Lùng"
  },
  {
    label: "Cửa khẩu Tây Trang",
    value: "Cửa khẩu Tây Trang"
  },
  {
    label: "Cửa khẩu Thanh Thủy",
    value: "Cửa khẩu Thanh Thủy"
  },
  {
    label: "Cửa khẩu Tịnh Biên",
    value: "Cửa khẩu Tịnh Biên"
  },
  {
    label: "Cửa khẩu Xa Mát",
    value: "Cửa khẩu Xa Mát"
  }
]
