import React, { forwardRef } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { UncontrolledTooltip } from "reactstrap"

const AgAction = forwardRef((props, ref) => {
  const { t } = useTranslation()
  return (
    <div className="d-flex gap-3">
      <Link to="#" className="text-success" onClick={props.data.action_edit}>
        <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
        <UncontrolledTooltip placement="top" target="edittooltip">
          {t("Edit")}
        </UncontrolledTooltip>
      </Link>
    </div>
  )
})

AgAction.displayName = "AgAction"

export default AgAction
