import { Col, Row, Container, Form, Input, Label, Card, CardBody } from "reactstrap"

import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//Breadcrumbs
import Breadcrumb from "components/Common/Breadcrumb"

//Modal
import Modal from "./Modal"

//Ag-grid
import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import AgButtonDVC from "./AgCellRendering/AgButtonDvc"
import AgText from "./AgCellRendering/AgText"

//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
//formik
import * as Yup from "yup"
import { useFormik } from "formik"

//services
import { ManualFetchDVCHoSo } from "services"
import Swal from "sweetalert2"

//config
import { AG_GRID_KEY } from "configs"

//responsive grid
import { getHeight } from "helpers/data_grid_helper"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const ModalSubmitNew = props => {
  const [data, setData] = useState([])
  const gridRef = useRef()
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const [heightTable, setHeightTable] = useState(500)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const validationSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      so_ho_so: ""
    },
    validationSchema: Yup.object({
      so_ho_so: Yup.string()
        .required()
        .test("check_sohoso", value => {
          if (!value) return true
          let regex = /G\d{2}\.\d{3}\.\d{3}\.\d{3}-\d{6}-\d{5}$/
          return value.match(regex)
        })
    }),
    onSubmit: (values, { resetForm }) => {
      try {
        Swal.fire({
          title: "Đang gửi",
          icon: "warning",
          text: "Vui lòng chờ ...",
          showConfirmButton: false
        })
        values.data_khachhang = JSON.stringify(data)
        console.log(values)
      } catch (error) {
        console.error(error)
      }
    }
  })

  /**Start ag grid config */
  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }
  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])
  const columnDefs = [
    {
      headerName: props.t("File number"),
      field: "so_ho_so",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left",
      minWidth: 280
    },
    {
      headerName: props.t("Profile status"),
      field: "trang_thai_ho_so",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left",
      cellRendererSelector: params => {
        const detail = {
          component: AgText
        }
        if (params.data) {
          return detail
        }
      }
    },
    {
      headerName: props.t("Submit date"),
      field: "ngay_nop",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter"
    },
    {
      headerName: props.t("Date of reception"),
      field: "ngay_tiep_nhan",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter"
    },
    {
      headerName: props.t("Ngày dự kiến trả"),
      field: "ngay_du_kien_tra",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter"
    },
    {
      headerName: props.t("Company Name"),
      field: "ten_cong_ty",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 550
    },
    {
      headerName: props.t("Detail"),
      field: "link_ket_qua",
      resizable: true,
      cellRendererSelector: params => {
        const detail = {
          component: AgButtonDVC
        }
        if (params.data) {
          return detail
        }
      },
      maxWidth: 150
    }
  ]
  /**End ag grid config */

  const handleCheckMahoso = () => {
    try {
      toggle()
      if (data.length > 0) {
        let so_ho_so = ""
        data.map((item, index) => {
          so_ho_so += item.so_ho_so
          if (index < data.length - 1) {
            so_ho_so += ","
          }
        })
        Swal.fire({
          title: props.t("Processing"),
          icon: "warning",
          text: props.t("Please waitting..."),
          showConfirmButton: false,
          allowOutsideClick: false
        })
        ManualFetchDVCHoSo({ so_ho_so })
          .then(res => {
            let result = res.data
            let temp = []
            result.map((item, index) => {
              temp.push({
                so_ho_so: item.so_ho_so,
                link_ket_qua: item.link_ket_qua,
                ngay_co_ket_qua: item.ngay_co_ket_qua,
                ngay_du_kien_tra: item.ngay_du_kien_tra,
                ngay_nop: item.ngay_nop,
                ngay_tiep_nhan: item.ngay_tiep_nhan,
                trang_thai_ho_so: item.trang_thai_ho_so,
                ten_cong_ty: item.cong_ty,
                error: item.error
              })
            })
            setData(temp)
            Swal.fire({
              title: props.t("Success"),
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            })
          })
          .catch(err => {
            console.log(err)
          })
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (validationSubmit.values.so_ho_so !== "") {
      let values = validationSubmit.values.so_ho_so
      values.replace(",", "\n")
      let value = values.split("\n")
      let temp = []
      value.map((item, index) => {
        temp.push({
          so_ho_so: item
        })
      })
      setData(temp)
    } else {
      setData([])
    }
  }, [validationSubmit.values.so_ho_so])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={props.t("Tool")} breadcrumbItem={props.t("Check status DVC")} />
          <Row>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validationSubmit.handleSubmit()
                return false
              }}
            >
              <Modal
                isOpen={isOpen}
                toggle={toggle}
                validationSubmit={validationSubmit}
                handleCheckMahoso={handleCheckMahoso}
              />
              <div className="d-flex justify-content-between mb-3 js-height">
                <button className="btn btn-secondary" type="button" onClick={toggle}>
                  {props.t("Check")}
                </button>
              </div>
            </Form>
          </Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              // rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              // enableRangeSelection={true}
              // onFirstDataRendered={params => {
              //   params.columnApi.autoSizeAllColumns()
              // }}
              enableCellChangeFlash={true}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

ModalSubmitNew.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalSubmitNew))
