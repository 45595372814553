import React from "react"
import { useFormikContext, useField } from "formik"
import DatePicker from "react-datepicker"
import classnames from "classnames"
import "react-datepicker/dist/react-datepicker.css"

import { Input as InputReact, FormGroup, Label, FormFeedback } from "reactstrap"

export default function Input({ label, id, name, inputClass, required = false }) {
  const { values, errors, handleChange, handleBlur, touched } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} className="col-form-label">
          {label}
          {required && (
            <>
              <span className="text-danger">*</span>
            </>
          )}
        </Label>
      )}
      <DatePicker
        autoComplete="do-not-autofill"
        portalId="root-portal"
        className={
          "form-control " +
          classnames({
            "border-danger": errors[name]
          })
        }
        name={name}
        id={id}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        showYearDropdown
        popperClassName="date-picker-reports"
        selected={values[name] || ""}
        onChange={value => helpers.setValue(value)}
        onBlur={() => helpers.setTouched(true)}
      />
      {errors[name] ? <FormFeedback style={{ display: "block" }}>{errors[name]}</FormFeedback> : null}
    </FormGroup>
  )
}
