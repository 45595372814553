import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import { Container, Button } from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { ListEvisaResult } from "services/"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgUrgentText from "./AgCellRendering/AgUrgentText"
import AgValueOfVisa from "./AgCellRendering/AgValueOfVisa"
import AgStayIn from "./AgCellRendering/AgStayIn"
import AgMucDichNhapCanh from "./AgCellRendering/AgMucDichNC"
import AgStatus from "./AgCellRendering/AgStatus"
import AgButtonDetail from "./AgCellRendering/AgButtonDetail"

import Modal from "./Modal"

import moment from "moment"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

import LoadingContext from "context/loading.context"

const ListEvisa = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [item, setItem] = useState({})
  const [data, setData] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const { setLoading } = useContext(LoadingContext)

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
      setItem({})
    } else {
      setIsOpen(true)
    }
  }

  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const fetchEvisa = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    const { data } = await ListEvisaResult(payload)
    setLoading(false)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      temp.push({
        ...item,
        create_at: moment(item.create_at).format("DD/MM/YYYY HH:mm:ss"),
        id: index,
        ho_ten: item.ho_ten,
        dob: item.ngay_sinh,
        so_tien_vnd: `${Number(item.so_tien_vnd).toLocaleString()} VND`,
        stayIn: item?.payload?.thoi_gian_du_dinh_cu_tru,
        urgent_title: item?.fee?.title_vn,
        multipleEntry_title: item?.payload?.gia_tri_thi_thuc_dien_tu === "n" ? t("Many times") : t("Once time"),
        visaType: item?.payload?.muc_dich_nhap_canh_label,
        detail: item?.payload ? true : false,
        email: item?.payload?.email
      })
    })
    setData(temp)
  }

  useEffect(() => {
    fetchEvisa()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Date Created"),
      field: "create_at",
      pinned: "left"
    },
    {
      headerName: t("Partner code"),
      field: "ma_doi_tac",
      pinned: "left",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Customer Name"),
      field: "ho_ten",
      pinned: "left"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Payment status"),
      field: "status",
      pinned: "left",
      cellRendererSelector: params => {
        const send = { component: AgStatus }
        if (params.data) return send
      }
    },
    {
      headerName: t("Processing Status"),
      field: "processing_status",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true,
      pinned: "left"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Detail"),
      field: "detail",
      pinned: "left",
      cellRendererSelector: params => {
        const send = { component: AgButtonDetail }
        if (params.data) return send
      },
      cellRendererParams: { setItem, toggle }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Mã hồ sơ"),
      field: "ma_hoso_dien_tu"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Date of birth"),
      field: "dob"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Email"),
      field: "email",
      minWidth: "400"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Passport number"),
      field: "so_ho_chieu"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Urgent"),
      field: "urgent_title",
      cellRendererSelector: params => {
        const text = { component: AgUrgentText }
        if (params.data) return text
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      sortable: false,
      cellRendererSelector: params => {
        const text = { component: AgValueOfVisa }
        if (params.data) return text
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Entry purpose"),
      field: "visaType",
      cellRendererSelector: params => {
        const send = { component: AgMucDichNhapCanh }
        if (params.data) return send
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Number of days of stay"),
      field: "stayIn",
      cellRendererSelector: params => {
        const send = { component: AgStayIn }
        if (params.data) return send
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Date of Entry"),
      field: "cap_thi_thuc_tu_ngay"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Departure Date"),
      field: "cap_thi_thuc_den_ngay"
    },
    {
      headerName: t("Nationality"),
      field: "quoc_tich",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Customer segmentation"),
      field: "phan_tach_khach_hang"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Số tiền thanh toán USD"),
      field: "so_tien_usd"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Số tiền thanh toán VND"),
      field: "so_tien_vnd"
    },
    {
      headerName: t("Additional requirement"),
      field: "ly_do_bo_tuc",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Modal isOpen={isOpen} item={item} toggle={toggle} />
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Danh sách khách hàng đã xác thực eVisa")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              statusBar={statusBar}
              onFirstDataRendered={params => {
                params.api.autoSizeAllColumns()
              }}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListEvisa
