import React, { forwardRef } from "react"
import { Badge } from "reactstrap"

const AgKey = forwardRef((props, ref) => {
  return (
    <>
      <Badge className={"font-size-12 badge text-bg-secondary"}>{props.data.key}</Badge>
    </>
  )
})

AgKey.displayName = "AgKey"

export default AgKey
