import React, { useRef, useState, useEffect, useMemo } from "react"

import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import { getHeight } from "helpers/data_grid_helper"
import { Container, Row } from "reactstrap"

import { AG_GRID_KEY } from "configs"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const SentListCVT = props => {
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)
  const [rowData, setRowData] = useState([])

  const columnDefs = [
    {
      headerName: "Date Created",
      field: "createAt",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: "Customer Name",
      field: "name",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])

  let gridStyle = { minHeight: 750, marginTop: 10 }

  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Row></Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              // isRowSelectable={isRowSelectable}
              // onGridReady={onGridReady}
              enableRangeSelection={true}
              // onFirstDataRendered={params => {
              //   params.columnApi.autoSizeAllColumns()
              // }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SentListCVT
