import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Container,
  Button
} from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { GetProxyList, CreateNewProxy, UpdateProxy } from "services/"
import PropTypes from "prop-types"

import * as Yup from "yup"
import { useFormik } from "formik"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgAction from "./AgCellRendering/AgAction"
import AgActive from "./AgCellRendering/AgActive"
import AgHttps from "./AgCellRendering/AgHttps"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

// Loading
import LoadingContext from "context/loading.context"

const Proxies = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState([])

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [proxy, setProxy] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")
  const { setLoading } = useContext(LoadingContext)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      _id: (proxy && proxy.id) || "",
      ip: (proxy && proxy.ip) || "",
      port: (proxy && proxy.port) || "",
      username: (proxy && proxy.username) || "",
      password: (proxy && proxy.password) || "",
      https: (proxy && proxy.https) || false,
      active: (proxy && proxy.active) || false
    },
    validationSchema: Yup.object({
      ip: Yup.string()
        .required()
        .test("check_valid_ip", "Please enter valid ip", val => {
          if (val) {
            const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
            return val.match(regex)
          }
          return true
        }),
      port: Yup.number().required().min(1000),
      username: Yup.string().required(),
      password: Yup.string().required()
    }),
    onSubmit: values => {
      setIsSuccess(false)
      setIsError(false)
      setMessage("")
      if (isEdit) {
        let proxy_raw = proxy
        let check_onchange = true
        values.active = values.active === "Yes" ? true : false
        proxy_raw.active = proxy.active === "Yes" ? true : false

        values.https = values.https === "Yes" ? true : false
        proxy_raw.https = proxy.https === "Yes" ? true : false

        if (
          proxy_raw.ip === values.ip &&
          proxy_raw.port === values.port &&
          proxy_raw.username === values.username &&
          proxy_raw.password === values.password &&
          proxy_raw.https === values.https &&
          proxy_raw.active === values.active
        ) {
          check_onchange = false
        }
        if (check_onchange === true) {
          UpdateProxy(values)
            .then(result => {
              setIsSuccess(true)
              setMessage("Update successfully")
              setIsEdit(false)
              // setTimeout(() => {
              //   validation.resetForm();
              //   toggle()
              // }, [3000])
              validation.resetForm()
              toggle()
            })
            .catch(err => {
              setIsSuccess(false)
              setIsError(true)
              setMessage(err.response?.data?.msg)
            })
        } else {
          setIsEdit(false)
          toggle()
        }
      } else {
        ;(values.https = values.https === "Yes" ? true : false),
          (values.active = values.active === "Yes" ? true : false)
        CreateNewProxy(values)
          .then(res => {
            setIsSuccess(true)
            setMessage("Create successfully")
            // setTimeout(() => {
            //   validation.resetForm();
            //   toggle()
            // }, [3000])
            validation.resetForm()
            toggle()
          })
          .catch(err => {
            setIsSuccess(false)
            setIsError(true)
            setMessage(err.response?.data?.msg)
          })
      }
    }
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setProxy(null)
    } else {
      setModal(true)
    }
  }

  const handleEdit = arg => {
    const proxy = arg
    setProxy({
      id: proxy._id,
      ip: proxy.ip,
      port: proxy.port,
      username: proxy.username,
      password: proxy.password,
      https: proxy.https === true ? "Yes" : "No",
      active: proxy.active === true ? "Yes" : "No"
    })
    setIsEdit(true)
    toggle()
  }

  const handleAdd = () => {
    setIsEdit(false)
    toggle()
  }

  const onClickDelete = order => {
    setProxy(order)
    setDeleteModal(true)
  }

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  let gridStyle = { minHeight: 750, marginTop: 10, width: "100%" }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const fetchData = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    const { data } = await GetProxyList(payload)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      // let country = quoctich.find(element => element.value == item.country);
      temp.push({
        ...item,
        action_edit: () => handleEdit(item),
        action_delete: () => onClickDelete(item)
      })
    })

    // Deplay loading
    if (data.length > 100) {
      setLoading(false)
      setData(temp)
    } else {
      setTimeout(() => {
        setLoading(false)
        setData(temp)
      }, 500)
    }
  }

  useEffect(() => {
    fetchData()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("IP"),
      field: "ip"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Port"),
      field: "port"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Username"),
      field: "username"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Password"),
      field: "password"
    },
    {
      headerName: t("HTTPS"),
      field: "https",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgHttps }
      }
    },
    {
      headerName: t("ACTIVE"),
      field: "active",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgActive }
      }
    },
    {
      headerName: t("ACTION"),
      field: "",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgAction }
      }
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit Proxy" : "Add New Proxies"}
          </ModalHeader>
          <ModalBody>
            {isError ? <Alert color="danger">{message}</Alert> : ""}
            {isSuccess ? <Alert color="success">{message}</Alert> : ""}
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Ip</Label>
                    <Input
                      name="ip"
                      type="text"
                      placeholder="Insert IP"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ip || ""}
                      invalid={validation.touched.ip && validation.errors.ip ? true : false}
                    />
                    {validation.touched.ip && validation.errors.ip ? (
                      <FormFeedback type="invalid">{validation.errors.ip}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Port</Label>
                    <Input
                      name="port"
                      type="number"
                      placeholder="1087"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.port || ""}
                      invalid={validation.touched.port && validation.errors.port ? true : false}
                    />
                    {validation.touched.port && validation.errors.port ? (
                      <FormFeedback type="invalid">{validation.errors.port}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Username</Label>
                    <Input
                      name="username"
                      type="text"
                      placeholder="Insert username"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={validation.touched.username && validation.errors.username ? true : false}
                    />
                    {validation.touched.username && validation.errors.username ? (
                      <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      type="text"
                      placeholder="Insert Password"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={validation.touched.password && validation.errors.password ? true : false}
                    />
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Https</Label>
                    <Input
                      name="https"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.https || ""}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </Input>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Active</Label>
                    <Input
                      name="active"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.active || ""}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </Input>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={isSuccess}>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Proxies")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
              <Button onClick={handleAdd} color="success" className="btn-rounded mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1" />
                {t("Add New Proxy")}
              </Button>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

Proxies.propTypes = {
  preGlobalFilteredRows: PropTypes.any
}

export default Proxies
