import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import {
  Container,
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert
} from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { GetListWorker } from "services/"
import PropTypes from "prop-types"

import * as Yup from "yup"
import { useFormik } from "formik"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgAction from "./AgCellRendering/AgAction"
import AgActive from "./AgCellRendering/AgActive"
import AgGlobalWorker from "./AgCellRendering/AgGlobalWorker"

import moment from "moment"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

// Loading
import LoadingContext from "context/loading.context"

const Worker = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState([])

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [worker, setWorker] = useState(null)
  const [partnerList, setPartnerList] = useState([])

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")
  const { setLoading } = useContext(LoadingContext)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      _id: (worker && worker.id) || "",
      code: (worker && worker.code) || "",
      fullname: (worker && worker.fullname) || "",
      gender: (worker && worker.gender) || false,
      dob: (worker && worker.dob) || "",
      email: (worker && worker.email) || "",
      phone: (worker && worker.phone) || "",
      commission: (worker && worker.commission) || "",
      owner: (worker && worker.owner) || "",
      status: (worker && worker.status) || false
    },
    validationSchema: Yup.object({
      code: Yup.string().required(),
      fullname: Yup.string().required(),
      dob: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: Yup.string().required(),
      commission: Yup.number().required().min(1000)
    }),
    onSubmit: values => {
      setIsSuccess(false)
      setIsError(false)
      setMessage("")
      if (isEdit) {
        let worker_raw = worker
        let check_onchange = true
        values.active = values.active === "Yes" ? true : false
        worker_raw.active = worker.active === "Yes" ? true : false

        values.https = values.https === "Yes" ? true : false
        worker_raw.https = worker.https === "Yes" ? true : false

        console.log(values)
        console.log(worker_raw)
        if (
          worker_raw.ip === values.ip &&
          worker_raw.port === values.port &&
          worker_raw.username === values.username &&
          worker_raw.password === values.password &&
          worker_raw.https === values.https &&
          worker_raw.active === values.active
        ) {
          check_onchange = false
        }
        if (check_onchange === true) {
          UpdateProxy(values)
            .then(result => {
              setIsSuccess(true)
              setMessage("Update successfully")
              setIsEdit(false)
              // setTimeout(() => {
              //   validation.resetForm();
              //   toggle()
              // }, [3000])
              validation.resetForm()
              toggle()
            })
            .catch(err => {
              setIsSuccess(false)
              setIsError(true)
              setMessage(err.response?.data?.msg)
            })
        } else {
          setIsEdit(false)
          toggle()
        }
      } else {
        ;(values.gender = values.gender === "Yes" ? true : false),
          (values.status = values.status === "Yes" ? true : false)
        CreateNewProxy(values)
          .then(res => {
            setIsSuccess(true)
            setMessage("Create successfully")
            // setTimeout(() => {
            //   validation.resetForm();
            //   toggle()
            // }, [3000])
            validation.resetForm()
            toggle()
          })
          .catch(err => {
            setIsSuccess(false)
            setIsError(true)
            setMessage(err.response?.data?.msg)
          })
      }
    }
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setWorker(null)
    } else {
      setModal(true)
    }
  }

  const handleEdit = arg => {
    const worker = arg
    setWorker({
      _id: worker.id,
      code: worker.code,
      fullname: worker.fullname,
      gender: worker.gender === true ? "Male" : "Female",
      dob: worker.dob,
      email: worker.email,
      phone: worker.phone,
      commission: worker.commission,
      owner: worker.owner,
      status: worker.status === true ? "Yes" : "No"
    })
    setIsEdit(true)
    toggle()
  }

  const handleAdd = () => {
    setIsEdit(false)
    toggle()
  }

  const onClickDelete = order => {
    setWorker(order)
    setDeleteModal(true)
  }

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const fetchData = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    const { data } = await GetListWorker(payload)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      temp.push({
        ...item,
        id: index + 1,
        gender: item.gender === true ? t("Male") : t("Female"),
        dob: moment(item.dob).format("DD/MM/YYYY"),
        action_edit: () => handleEdit(item),
        action_delete: () => onClickDelete(item)
      })
    })

    // Deplay loading
    if (data.length > 100) {
      setLoading(false)
      setData(temp)
    } else {
      setTimeout(() => {
        setLoading(false)
        setData(temp)
      }, 500)
    }
  }

  useEffect(() => {
    fetchData()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
    // type: "width_default"
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Mã nhân viên"),
      field: "code"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Full name"),
      field: "fullname"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Gender"),
      field: "gender"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Dob"),
      field: "dob"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Email"),
      field: "email",
      type: ""
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Phone number"),
      field: "phone"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Commission"),
      field: "commission"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Owner"),
      field: "owner"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Active"),
      field: "status",
      cellRendererSelector: params => {
        if (params.data) return { component: AgActive }
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("GlobalWorker"),
      field: "workerGlobal",
      cellRendererSelector: params => {
        if (params.data) return { component: AgGlobalWorker }
      }
    },
    {
      headerName: t("ACTION"),
      field: "",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgAction }
      }
    }
  ]

  const columnTypes = useMemo(() => {
    return {
      width_default: {
        width: "150"
      }
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit Worker" : "Add New Worker"}
          </ModalHeader>
          <ModalBody>
            {isError ? <Alert color="danger">{message}</Alert> : ""}
            {isSuccess ? <Alert color="success">{message}</Alert> : ""}
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Login Code</Label>
                    <Input
                      name="code"
                      type="text"
                      placeholder="Insert code"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.code || ""}
                      invalid={validation.touched.code && validation.errors.code ? true : false}
                    />
                    {validation.touched.code && validation.errors.code ? (
                      <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Fullname</Label>
                    <Input
                      name="fullname"
                      type="text"
                      placeholder="1087"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.fullname || ""}
                      invalid={validation.touched.fullname && validation.errors.fullname ? true : false}
                    />
                    {validation.touched.fullname && validation.errors.fullname ? (
                      <FormFeedback type="invalid">{validation.errors.fullname}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Gender</Label>
                    <Input
                      name="gender"
                      type="select"
                      placeholder="Select gender"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.gender || ""}
                      invalid={validation.touched.gender && validation.errors.gender ? true : false}
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </Input>
                    {validation.touched.gender && validation.errors.gender ? (
                      <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Dob</Label>
                    <DatePicker
                      className={
                        "form-control" + (validation.touched.dob && validation.errors.dob ? " border-danger" : "")
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      selected={validation.values.dob && new Date(validation.values.dob)}
                      name="dob"
                      placeholder="Insert Password"
                      onChange={val => {
                        validation.setFieldValue("dob", val === null ? "" : val)
                      }}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.dob && validation.errors.dob ? (
                      <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      type="text"
                      placeholder="Insert email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={validation.touched.email && validation.errors.email ? true : false}
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Phone</Label>
                    <Input
                      name="phone"
                      type="text"
                      placeholder="Insert phone"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={validation.touched.phone && validation.errors.phone ? true : false}
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Commission</Label>
                    <Input
                      name="commission"
                      type="number"
                      placeholder="Insert Commission"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.commission}
                      invalid={validation.touched.commission && validation.errors.commission ? true : false}
                    />
                    {validation.touched.commission && validation.errors.commission ? (
                      <FormFeedback type="invalid">{validation.errors.commission}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Owner by</Label>
                    <Input
                      name="owner"
                      type="select"
                      placeholder="Select owner"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.owner || ""}
                      invalid={validation.touched.owner && validation.errors.owner ? true : false}
                    >
                      <option key={0}>For all</option>
                      {partnerList &&
                        partnerList.map((item, index) => {
                          return <option key={index + 1}>{item.username}</option>
                        })}
                    </Input>
                    {validation.touched.owner && validation.errors.owner ? (
                      <FormFeedback type="invalid">{validation.errors.owner}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Input
                      name="status"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.status || ""}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </Input>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={isSuccess}>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Quản lý nhân viên")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
              <Button onClick={handleAdd} color="success" className="btn-rounded mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1" />
                {t("Add New Worker")}
              </Button>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              columnTypes={columnTypes}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

Worker.propTypes = {
  preGlobalFilteredRows: PropTypes.any
}

export default Worker
