import React, { forwardRef } from "react"

const AgActive = forwardRef((props, ref) => {
  return (
    <>
      <span>
        <i
          className={
            props.data.active === true ? "fas fa-check" : "" || props.data.active === false ? "fas fa-times" : ""
          }
          style={{ color: props.data.active === true ? "#1eff00" : "#ff0000" }}
        />{" "}
        {props.data.active}
      </span>
    </>
  )
})

AgActive.displayName = "AgActive"

export default AgActive
