import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Container,
  Button
} from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { CreateNewEvisaFakeInfo, UpdateEvisaFakeInfo, ListEvisaFakeInfo, RemoveEvisaFakeInfo } from "services/"
import PropTypes from "prop-types"

import * as Yup from "yup"
import { useFormik } from "formik"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgAction from "./AgCellRendering/AgAction"

//Import Date Picker
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"

// Loading
import LoadingContext from "context/loading.context"
import CommonDeleteModal from "components/Common/CommonDeleteModal"
import FlagsCellRenderer from "./AgCellRendering/FlagsCellRenderer"

const FakeInfo = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState([])

  // const [startDate, setStartDate] = useState()
  // const [endDate, setEndDate] = useState()
  // const [selectedDate, setSelectedDate] = useState()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [dataInfo, setDataInfo] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")
  const { setLoading } = useContext(LoadingContext)
  const [deleteModal, setDeleteModal] = useState(false)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      _id: (dataInfo && dataInfo.id) || "",
      quoc_tich: (dataInfo && dataInfo.quoc_tich) || "",
      ttll_so_dien_thoai: (dataInfo && dataInfo.ttll_so_dien_thoai) || "",
      ttll_dia_chi_lien_lac: (dataInfo && dataInfo.ttll_dia_chi_lien_lac) || "",
      ttll_lhkc_so_dien_thoai: (dataInfo && dataInfo.ttll_lhkc_so_dien_thoai) || "",
      ttll_lhkc_ho_va_ten: (dataInfo && dataInfo.ttll_lhkc_ho_va_ten) || "",
      ttll_lhkc_noi_o_hien_nay: (dataInfo && dataInfo.ttll_lhkc_noi_o_hien_nay) || "",
      ttnn_ten_cong_ty: (dataInfo && dataInfo.ttnn_ten_cong_ty) || "",
      ttnn_dia_chi_cong_ty: (dataInfo && dataInfo.ttnn_dia_chi_cong_ty) || "",
      ttnn_sdt_cong_ty: (dataInfo && dataInfo.ttnn_sdt_cong_ty) || ""
    },
    validationSchema: Yup.object({
      quoc_tich: Yup.string().required(t("Country is a required field")),
      ttll_so_dien_thoai: Yup.number().required(t("TTLL Phone number is a required field")),
      ttll_lhkc_so_dien_thoai: Yup.number().required(t("TTLL KC Phone number is a required field")),
      ttnn_sdt_cong_ty: Yup.number().required(t("TTNN Phone number is a required field")),
      ttll_lhkc_ho_va_ten: Yup.string().required(t("TTLL KC Username is a required field")),
      ttll_lhkc_noi_o_hien_nay: Yup.string().required(t("TTLL KC Residence is a required field")),
      ttnn_ten_cong_ty: Yup.string().required(t("TTNN Company is a required field")),
      ttnn_dia_chi_cong_ty: Yup.string().required(t("TTNN Address is a required field")),
      ttll_dia_chi_lien_lac: Yup.string().required(t("TTLL Address is a required field"))
    }),
    onSubmit: values => {
      setIsSuccess(false)
      setIsError(false)
      setMessage("")
      if (isEdit) {
        let dataInfo_raw = dataInfo
        let check_onchange = true

        if (
          dataInfo_raw.quoc_tich === values.quoc_tich &&
          dataInfo_raw.ttll_dia_chi_lien_lac === values.ttll_dia_chi_lien_lac &&
          dataInfo_raw.ttll_so_dien_thoai === values.ttll_so_dien_thoai &&
          dataInfo_raw.ttll_lhkc_so_dien_thoai === values.ttll_lhkc_so_dien_thoai &&
          dataInfo_raw.ttll_lhkc_ho_va_ten === values.ttll_lhkc_ho_va_ten &&
          dataInfo_raw.ttll_lhkc_noi_o_hien_nay === values.ttll_lhkc_noi_o_hien_nay &&
          dataInfo_raw.ttnn_ten_cong_ty === values.ttnn_ten_cong_ty &&
          dataInfo_raw.ttnn_dia_chi_cong_ty === values.ttnn_dia_chi_cong_ty &&
          dataInfo_raw.ttnn_sdt_cong_ty === values.ttnn_sdt_cong_ty
        ) {
          check_onchange = false
        }
        if (check_onchange === true) {
          UpdateEvisaFakeInfo(values)
            .then(() => {
              setIsSuccess(true)
              setMessage("Update successfully")
              setIsEdit(false)
              validation.resetForm()
              fetchData()
              toggle()
            })
            .catch(err => {
              setIsSuccess(false)
              setIsError(true)
              setMessage(err.response?.data?.msg)
            })
        } else {
          setIsEdit(false)
          toggle()
        }
      } else {
        CreateNewEvisaFakeInfo(values)
          .then(() => {
            setIsSuccess(true)
            setMessage("Create successfully")
            validation.resetForm()
            fetchData()
            toggle()
          })
          .catch(err => {
            setIsSuccess(false)
            setIsError(true)
            setMessage(err.response?.data?.msg)
          })
      }
    }
  })

  const toggle = () => {
    setIsSuccess(false)
    setIsError(false)
    setMessage("")
    if (modal) {
      setModal(false)
      setDataInfo(null)
    } else {
      setModal(true)
    }
  }

  const handleEdit = arg => {
    const dataInfo = arg
    setModal(true)
    setDataInfo({
      id: dataInfo._id,
      quoc_tich: dataInfo.quoc_tich,
      ttll_dia_chi_lien_lac: dataInfo.ttll_dia_chi_lien_lac,
      ttll_so_dien_thoai: dataInfo.ttll_so_dien_thoai,
      ttll_lhkc_so_dien_thoai: dataInfo.ttll_lhkc_so_dien_thoai,
      ttll_lhkc_ho_va_ten: dataInfo.ttll_lhkc_ho_va_ten,
      ttll_lhkc_noi_o_hien_nay: dataInfo.ttll_lhkc_noi_o_hien_nay,
      ttnn_ten_cong_ty: dataInfo.ttnn_ten_cong_ty,
      ttnn_dia_chi_cong_ty: dataInfo.ttnn_dia_chi_cong_ty,
      ttnn_sdt_cong_ty: dataInfo.ttnn_sdt_cong_ty
    })
    setIsEdit(true)
  }

  const handleAdd = () => {
    setIsEdit(false)
    toggle()
  }

  const onClickDelete = order => {
    setDataInfo(order)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    setIsSuccess(false)
    setIsError(false)
    setMessage("")
    if (dataInfo && dataInfo._id) {
      RemoveEvisaFakeInfo(dataInfo._id)
        .then(res => {
          if (res.status === 200) {
            setIsSuccess(true)
            fetchData()
          } else {
            setIsSuccess(false)
            setIsError(true)
            setMessage(res.data.msg)
          }
        })
        .catch(err => {
          setIsSuccess(false)
          setIsError(true)
          setMessage(err.response?.data?.msg)
        })
      setDeleteModal(false)
    }
  }

  // const optionDateSelect = [
  //   { label: "Hôm nay", value: "today" },
  //   { label: "Hôm qua", value: "yesterday" },
  //   { label: "3 ngày trước", value: "3daysago" },
  //   { label: "7 ngày trước", value: "7daysago" },
  //   { label: "15 ngày trước", value: "15daysago" },
  //   { label: "30 ngày trước", value: "30daysago" },
  // ]

  // const handleDateSelect = value => {
  //   let today = new Date()
  //   let last_date = new Date()
  //   last_date.setDate(today.getDate() - 1)
  //   setEndDate(last_date)
  //   switch (value) {
  //     case "today":
  //       setStartDate(today)
  //       setEndDate(today)
  //       break
  //     case "yesterday":
  //       let yesterday = new Date(today)
  //       yesterday.setDate(today.getDate() - 1)
  //       setStartDate(yesterday)
  //       break
  //     case "3daysago":
  //       let threedaysago = new Date(today)
  //       threedaysago.setDate(today.getDate() - 3)
  //       setStartDate(threedaysago)
  //       break
  //     case "7daysago":
  //       let sevendaysago = new Date(today)
  //       sevendaysago.setDate(today.getDate() - 7)
  //       setStartDate(sevendaysago)
  //       break
  //     case "15daysago":
  //       let fifteendaysago = new Date(today)
  //       fifteendaysago.setDate(today.getDate() - 15)
  //       setStartDate(fifteendaysago)
  //       break
  //     case "30daysago":
  //       let thirtydaysago = new Date(today)
  //       thirtydaysago.setDate(today.getDate() - 30)
  //       setStartDate(thirtydaysago)
  //       break
  //     default:
  //       setStartDate()
  //       setEndDate()
  //       setSelectedDate(null)
  //       break
  //   }
  // }

  // const validateStartAndEnd = () => {
  //   if (startDate && endDate) {
  //     if (startDate > endDate) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  //   return null
  // }

  let gridStyle = { minHeight: 750, marginTop: 10, width: "100%" }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  // const formatDate = date => {
  //   const year = date.getFullYear()
  //   const month = String(date.getMonth() + 1).padStart(2, "0")
  //   const day = String(date.getDate()).padStart(2, "0")
  //   return `${year}-${month}-${day}`
  // }

  const fetchData = async () => {
    let payload = {}
    // if (startDate && endDate) {
    //   let status = validateStartAndEnd()
    //   if (status === false) {
    //     payload.startDate = formatDate(startDate)
    //     payload.endDate = formatDate(endDate)
    //   }
    // }
    setLoading(true)
    const { data } = await ListEvisaFakeInfo(payload)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      temp.push({
        ...item,
        action_edit: () => handleEdit(item),
        action_delete: () => onClickDelete(item)
      })
    })

    // Deplay loading
    if (data.length > 100) {
      setLoading(false)
      setData(temp)
    } else {
      setTimeout(() => {
        setLoading(false)
        setData(temp)
      }, 500)
    }
  }

  useEffect(() => {
    fetchData()
  }, [t])
  // }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Country"),
      field: "quoc_tich",
      cellRendererSelector: params => {
        if (params.data) return { component: FlagsCellRenderer }
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTLL Phone number"),
      field: "ttll_so_dien_thoai"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTLL Address"),
      field: "ttll_dia_chi_lien_lac"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTLL KC Username"),
      field: "ttll_lhkc_ho_va_ten"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTLL KC Phone number"),
      field: "ttll_lhkc_so_dien_thoai"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTLL KC Residence"),
      field: "ttll_lhkc_noi_o_hien_nay"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTNN Company"),
      field: "ttnn_ten_cong_ty"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTNN Phone number"),
      field: "ttnn_sdt_cong_ty"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("TTNN Addresss"),
      field: "ttnn_dia_chi_cong_ty"
    },
    {
      headerName: t("ACTION"),
      field: "",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgAction }
      }
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader tag="h4">{!!isEdit ? "Edit Fake Info" : "Add New Fake Info"}</ModalHeader>
          <ModalBody>
            {isError ? <Alert color="danger">{message}</Alert> : ""}
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">{t("Country")}</Label>
                    <Input
                      name="quoc_tich"
                      type="text"
                      placeholder="Insert quoc_tich"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.quoc_tich || ""}
                      invalid={validation.touched.quoc_tich && validation.errors.quoc_tich ? true : false}
                    />
                    {validation.touched.quoc_tich && validation.errors.quoc_tich ? (
                      <FormFeedback type="invalid">{validation.errors.quoc_tich}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTLL Phone number")}</Label>
                    <Input
                      name="ttll_so_dien_thoai"
                      type="text"
                      placeholder="Insert ttll_so_dien_thoai"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttll_so_dien_thoai || ""}
                      invalid={
                        validation.touched.ttll_so_dien_thoai && validation.errors.ttll_so_dien_thoai ? true : false
                      }
                    />
                    {validation.touched.ttll_so_dien_thoai && validation.errors.ttll_so_dien_thoai ? (
                      <FormFeedback type="invalid">{validation.errors.ttll_so_dien_thoai}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTLL Address")}</Label>
                    <Input
                      name="ttll_dia_chi_lien_lac"
                      type="text"
                      placeholder="Insert ttll_dia_chi_lien_lac"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttll_dia_chi_lien_lac || ""}
                      invalid={
                        validation.touched.ttll_dia_chi_lien_lac && validation.errors.ttll_dia_chi_lien_lac
                          ? true
                          : false
                      }
                    />
                    {validation.touched.ttll_dia_chi_lien_lac && validation.errors.ttll_dia_chi_lien_lac ? (
                      <FormFeedback type="invalid">{validation.errors.ttll_dia_chi_lien_lac}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTLL KC Username")}</Label>
                    <Input
                      name="ttll_lhkc_ho_va_ten"
                      type="text"
                      placeholder="Insert ttll_lhkc_ho_va_ten"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttll_lhkc_ho_va_ten || ""}
                      invalid={
                        validation.touched.ttll_lhkc_ho_va_ten && validation.errors.ttll_lhkc_ho_va_ten ? true : false
                      }
                    />
                    {validation.touched.ttll_lhkc_ho_va_ten && validation.errors.ttll_lhkc_ho_va_ten ? (
                      <FormFeedback type="invalid">{validation.errors.ttll_lhkc_ho_va_ten}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTLL KC Phone number")}</Label>
                    <Input
                      name="ttll_lhkc_so_dien_thoai"
                      type="text"
                      placeholder="Insert ttll_lhkc_so_dien_thoai"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttll_lhkc_so_dien_thoai || ""}
                      invalid={
                        validation.touched.ttll_lhkc_so_dien_thoai && validation.errors.ttll_lhkc_so_dien_thoai
                          ? true
                          : false
                      }
                    />
                    {validation.touched.ttll_lhkc_so_dien_thoai && validation.errors.ttll_lhkc_so_dien_thoai ? (
                      <FormFeedback type="invalid">{validation.errors.ttll_lhkc_so_dien_thoai}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTLL KC Residence")}</Label>
                    <Input
                      name="ttll_lhkc_noi_o_hien_nay"
                      type="text"
                      placeholder="Insert ttll_lhkc_noi_o_hien_nay"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttll_lhkc_noi_o_hien_nay || ""}
                      invalid={
                        validation.touched.ttll_lhkc_noi_o_hien_nay && validation.errors.ttll_lhkc_noi_o_hien_nay
                          ? true
                          : false
                      }
                    />
                    {validation.touched.ttll_lhkc_noi_o_hien_nay && validation.errors.ttll_lhkc_noi_o_hien_nay ? (
                      <FormFeedback type="invalid">{validation.errors.ttll_lhkc_noi_o_hien_nay}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTNN Company")}</Label>
                    <Input
                      name="ttnn_ten_cong_ty"
                      type="text"
                      placeholder="Insert ttnn_ten_cong_ty"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttnn_ten_cong_ty || ""}
                      invalid={validation.touched.ttnn_ten_cong_ty && validation.errors.ttnn_ten_cong_ty ? true : false}
                    />
                    {validation.touched.ttnn_ten_cong_ty && validation.errors.ttnn_ten_cong_ty ? (
                      <FormFeedback type="invalid">{validation.errors.ttnn_ten_cong_ty}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTNN Phone number")}</Label>
                    <Input
                      name="ttnn_sdt_cong_ty"
                      type="text"
                      placeholder="Insert ttnn_sdt_cong_ty"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttnn_sdt_cong_ty || ""}
                      invalid={validation.touched.ttnn_sdt_cong_ty && validation.errors.ttnn_sdt_cong_ty ? true : false}
                    />
                    {validation.touched.ttnn_sdt_cong_ty && validation.errors.ttnn_sdt_cong_ty ? (
                      <FormFeedback type="invalid">{validation.errors.ttnn_sdt_cong_ty}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{t("TTNN Address")}</Label>
                    <Input
                      name="ttnn_dia_chi_cong_ty"
                      type="text"
                      placeholder="Insert ttnn_dia_chi_cong_ty"
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ttnn_dia_chi_cong_ty || ""}
                      invalid={
                        validation.touched.ttnn_dia_chi_cong_ty && validation.errors.ttnn_dia_chi_cong_ty ? true : false
                      }
                    />
                    {validation.touched.ttnn_dia_chi_cong_ty && validation.errors.ttnn_dia_chi_cong_ty ? (
                      <FormFeedback type="invalid">{validation.errors.ttnn_dia_chi_cong_ty}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={isSuccess}>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Danh sách Evisa Fake Info")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                {/* <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button> */}
              </div>
              <Button onClick={handleAdd} color="success" className="btn-rounded mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1" />
                {t("Add New Fake Info")}
              </Button>
            </div>
            {/* {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )} */}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              statusBar={statusBar}
              onFirstDataRendered={params => {
                params.api.autoSizeAllColumns()
              }}
            />
          </div>
        </Container>
        <CommonDeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => setDeleteModal(false)} />
      </div>
    </>
  )
}

FakeInfo.propTypes = {
  preGlobalFilteredRows: PropTypes.any
}

export default FakeInfo
