import { Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap"
import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const ModalEvisa = props => {
  let { item, isOpen, toggle } = props

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog modal-dialog-centered">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Profile details")}
          </h5>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container>
            <Row>
              <Col xl="12">
                <Card className="w-100">
                  <CardBody className="p-0" style={{ backgroundColor: "#fbfbfc" }}>
                    <h5>
                      <b>Số hồ sơ:</b> {item.mahoso}
                    </h5>
                    <h5>
                      <b>Trạng thái hồ sơ:</b>{" "}
                      {item && item.thong_tin_ho_so ? item.thong_tin_ho_so.trang_thai_ho_so : ""}
                    </h5>
                    <h5>
                      <b>Ngày nộp:</b> {item && item.thong_tin_ho_so ? item.thong_tin_ho_so.ngay_nop : ""}
                    </h5>
                    <h5>
                      <b>Ngày tiếp nhận:</b> {item && item.thong_tin_ho_so ? item.thong_tin_ho_so.ngay_tiep_nhan : ""}
                    </h5>
                    <h5>
                      <b>Yêu cầu bổ sung:</b> {item && item.thong_tin_ho_so ? item.thong_tin_ho_so.yeu_cau_bo_sung : ""}
                    </h5>
                    <h5>
                      <b>Tên công ty:</b> {item && item.thong_tin_ho_so ? item.thong_tin_ho_so.ten_cong_ty : ""}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalEvisa.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalEvisa))
