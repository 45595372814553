import PropTypes from "prop-types"
import React, { useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Modal } from "reactstrap"

//redux
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

import {
  // LoginByCode,
  LoginByUsernameAndPassword,
  LoginNhanvienByCodeAndMaDoiTac
} from "../../services/auth"

//i18n
import { withTranslation } from "react-i18next"
import Cookies from "universal-cookie"
import { STORAGE_KEY, STORAGE_KEY_TIMEOUT } from "configs/app.const"

const Login = props => {
  //meta title
  document.title = "Login"
  const cookies = new Cookies()
  const history = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || '',
      // password: "123456" || '',
      // code: ''
      username: "",
      password: "",
      isRemember: true
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Your Code")),
      // email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password")
    }),
    onSubmit: async values => {
      // dispatch(loginUser(values, props.router.navigate));

      await LoginByUsernameAndPassword(values)
        .then(res => {
          let result = res.data
          cookies.set(STORAGE_KEY.auth, result.token, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
          cookies.set(STORAGE_KEY.madoitac, values.username, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
          if (values.isRemember) {
            cookies.set(STORAGE_KEY.refresh_token, result.refreshToken, {
              path: "/",
              maxAge: STORAGE_KEY_TIMEOUT.refresh_token
            })
          } else {
            cookies.remove(STORAGE_KEY.refresh_token)
          }
          setIsSuccess(true)
          setIsError(false)
          history("/")
        })
        .catch(err => {
          console.error(err)
          setIsSuccess(false)
          setIsError(true)
          setError(err?.response?.data?.msg)
        })
    }
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                        <p>{props.t("Sign in to continue to VNPassport.")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {isError === true ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Code")}</Label>
                        <Input
                          name="username"
                          value={validation.values.username || ""}
                          type="text"
                          placeholder={props.t("username")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.username && validation.errors.username ? true : false}
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        {/* <Label className="form-label">Password</Label> */}
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.password && validation.errors.password ? true : false}
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="">
                        <Label className="form-label mx-2">Remember me</Label>
                        <Input
                          name="isRemember"
                          type="checkbox"
                          defaultChecked={true}
                          value={validation.values.isRemember}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block" type="submit">
                          {props.t("Log In")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object
}

Login.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(Login))
