import React, { forwardRef, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { Badge } from "reactstrap"
import { Link } from "react-router-dom"

const AgAction = forwardRef((props, ref) => {
  const { t } = useTranslation()
  // console.log(props);
  return (
    <>
      <div className="d-flex gap-3">
        <Link to={"/partner/edit/" + props.data._id} className="text-success">
          <i className="mdi mdi-pencil font-size-18" />
        </Link>
        <Link to="#" className="text-danger" disabled={true} onClick={props.data.action_view}>
          <i className="mdi mdi-eye font-size-18" />
        </Link>
        <Link to="#" className="text-primary" disabled={true} onClick={props.data.action_clone}>
          <i className="mdi mdi-content-copy font-size-18" />
        </Link>
      </div>
    </>
  )
})

AgAction.displayName = "MyImageRenderer"

export default AgAction
