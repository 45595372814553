import axios from "axios"
import { API_URL } from "../../configs/"
import { STORAGE_KEY } from "../../configs/app.const"
import Cookies from "universal-cookie"
const cookies = new Cookies()

export default axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: "Bearer " + cookies.get(STORAGE_KEY.auth) !== undefined ? cookies.get(STORAGE_KEY.auth) : "",
    "content-type": "multipart/form-data"
  }
})
