import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"

const PartnerChargeModal = props => {
  const { isOpen, data, toggle } = props
  const CVT = data?.urgent_model_CVT
  const EVISA = data?.urgent_model_EVISA

  const formatNumber = data => {
    return parseInt(data).toLocaleString()
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        toggle={toggle}
        className="modal-dialog modal-lg"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Chare Model Detail</ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Partner: <span className="text-primary">{data?.username}</span>
            </p>
            {/* <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p> */}

            <div className="table-responsive">
              <p>CVT</p>
              <Table className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Title VN</th>
                    <th scope="col">Title EN</th>
                    <th scope="col">Price</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Vip Fee</th>
                    <th scope="col">Service Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {CVT &&
                    CVT.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">
                              <div>
                                <h5 className="text-truncate font-size-14">{item.title_vn}</h5>
                              </div>
                            </th>
                            <td>
                              <div>
                                <h5 className="text-truncate font-size-14">{item.title_en}</h5>
                              </div>
                            </td>
                            <td>{formatNumber(item.gia_ban)}</td>
                            <td>{formatNumber(item.gia_von)}</td>
                            <td>{formatNumber(item.vip_fee)}</td>
                            <td>{formatNumber(item.service_fee)}</td>
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </Table>
              <p>EVISA</p>
              <Table className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Title VN</th>
                    <th scope="col">Title EN</th>
                    <th scope="col">Price</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Vip Fee</th>
                    <th scope="col">Service Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {EVISA &&
                    EVISA.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">
                              <div>
                                <h5 className="text-truncate font-size-14">{item.title_vn}</h5>
                              </div>
                            </th>
                            <td>
                              <div>
                                <h5 className="text-truncate font-size-14">{item.title_en}</h5>
                              </div>
                            </td>
                            <td>{formatNumber(item.gia_ban)}</td>
                            <td>{formatNumber(item.gia_von)}</td>
                            <td>{formatNumber(item.vip_fee)}</td>
                            <td>{formatNumber(item.service_fee)}</td>
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default PartnerChargeModal
