import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import {
  Col,
  Row,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Container,
  Button,
  CardBody,
  CardHeader
} from "reactstrap"

//import react-select
import Select from "react-select"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"

import * as Yup from "yup"
import { useFormik } from "formik"

import LoadingContext from "context/loading.context"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//services
import { ListPartnerResult, GetStatisticalDoitac } from "services"

const Statistical = () => {
  const { setLoading } = useContext(LoadingContext)

  const [list_doi_tac, setListDoiTac] = useState([])

  const [data, setData] = useState({})

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const res = await ListPartnerResult()
      if (res.data) {
        let list = res.data.map(item => {
          return {
            value: item.username,
            label: item.username
          }
        })
        setListDoiTac(list)
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  const validationSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ma_doi_tac: "",
      startDate: "",
      endDate: ""
    },
    validationSchema: Yup.object({
      ma_doi_tac: Yup.string().required("Chọn đối tác")
      // startDate: Yup.date().required("Chọn ngày bắt đầu"),
      // endDate: Yup.date().required("Chọn ngày kết thúc")
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log(values)
      setLoading(true)
      const res = await GetStatisticalDoitac(values)
      if (res.data) {
        setLoading(false)
        setData(res.data)
      } else {
        setLoading(false)
      }
    },
    validateOnChange: true,
    validateOnBlur: true
  })

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Đối tác" breadcrumbItem="Thống kê đối tác" />
          <Row>
            <Col lg="12">
              <Card className="d-flex">
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validationSubmit.handleSubmit()
                    return false
                  }}
                >
                  <Col lg="12" className="d-flex justify-content-between p-3 rounded-3">
                    <Col lg="3">
                      <div>
                        <Label for="ma_doi_tac">Mã đối tác:</Label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor:
                                validationSubmit.touched.ma_doi_tac && validationSubmit.errors.ma_doi_tac
                                  ? "red"
                                  : "#ced4da !important"
                            })
                          }}
                          id="ma_doi_tac"
                          name="ma_doi_tac"
                          isSearchable
                          placeholder="Chọn đối tác"
                          options={list_doi_tac}
                          onBlur={validationSubmit.handleBlur}
                          onChange={e => {
                            validationSubmit.setFieldValue("ma_doi_tac", e.value)
                          }}
                        />
                        {validationSubmit.touched.ma_doi_tac && validationSubmit.errors.ma_doi_tac ? (
                          <FormFeedback type="invalid">{validationSubmit.errors.ma_doi_tac}</FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col lg="3">
                      <div>
                        <Label for="startDate">Ngày bắt đầu</Label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          id="startDate"
                          name="startDate"
                          className={
                            "form-control" +
                            (validationSubmit.touched.startDate && validationSubmit.errors.startDate
                              ? " border-danger"
                              : "")
                          }
                          selected={(validationSubmit.values.startDate && new Date(validationSubmit.values.startDate)) || ""}
                          onBlur={validationSubmit.handleBlur}
                          onChange={val => {
                            validationSubmit.setFieldValue("startDate", val === null ? "" : val)
                          }}
                        />
                        {validationSubmit.touched.startDate && validationSubmit.errors.startDate ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmit.errors.startDate}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col lg="3">
                      <div>
                        <Label for="endDate">Ngày kết thúc</Label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          id="endDate"
                          name="endDate"
                          className={
                            "form-control" +
                            (validationSubmit.touched.endDate && validationSubmit.errors.endDate
                              ? " border-danger"
                              : "")
                          }
                          selected={(validationSubmit.values.endDate && new Date(validationSubmit.values.endDate)) || ""}
                          onBlur={validationSubmit.handleBlur}
                          onChange={val => {
                            validationSubmit.setFieldValue("endDate", val === null ? "" : val)
                          }}
                        />
                        {validationSubmit.touched.endDate && validationSubmit.errors.endDate ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmit.errors.endDate}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col lg="2" className="justify-content-end mt-4">
                      <Button type="submit" color="dark">
                        Kiểm tra
                      </Button>
                    </Col>
                  </Col>
                </Form>
              </Card>

              <Card>
                <CardBody>
                  <h5 className="fw-bold">Kết quả:</h5>
                  {/* {data && ( */}
                  <div>
                    <Row>
                      <Col md={6}>
                        <Col className="p-3 rounded-3 border border-1 border-primary">
                          <h5 className="fw-bold">Công văn thường: {data.passport?.passport_verified} khách</h5>
                          {/* <h5>Tổng số khách: {data.passport?.passport_verified}</h5> */}
                          <Col md={12} className="d-flex">
                            <Col md={6}>
                              <h5>Loại thị thực Một lần: {data.passport?.passport_verified_mot_lan}</h5>
                              <h5>Loại thị thực Nhiều lần: {data.passport?.passport_verified_nhieu_lan}</h5>
                            </Col>
                            <Col md={6}>
                              {data.passport?.cvt_numberOfStay && data.passport?.cvt_numberOfStay.map((item, index) => {
                                return (
                                  <h5 key={index}>Loại thị thực {item.stayIn} ngày: {item.count}</h5>
                                )
                              })}
                            </Col>
                          </Col>
                        </Col>
                        <Col className="p-3 rounded-3 border border-1 border-primary mt-2">
                          <div>
                            <h5 className="fw-bold">Số lượng theo khẩn CVT</h5>
                            <div>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>STT</th>
                                    <th>Loại khẩn</th>
                                    <th>Số lượng</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.passport?.response_urgent_cvt &&
                                    data.passport?.response_urgent_cvt.map((item, index) => {
                                      if(Number(item.passport_verified) > 0) {
                                        return (
                                          <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.title}</td>
                                            <td>{item.passport_verified}</td>
                                          </tr>
                                        )
                                      }
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                        <Col className="p-3 rounded-3 border border-1 border-primary mt-2">
                          <div>
                            <h5 className="fw-bold">Số lượng theo nơi nhận thị thực CVT</h5>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>STT</th>
                                  <th>Nơi nhận thị thực</th>
                                  <th>Số lượng</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.passport?.cvt_addToGetVisa &&
                                  data.passport?.cvt_addToGetVisa.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.addToGetVisa}</td>
                                        <td>{item.count}</td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Col>
                      {/* Evisa */}
                      <Col md={6}>
                        <Col md={12}>
                          <Col className="p-3 rounded-3 border border-1 border-primary">
                            <h5 className="fw-bold">EVISA: {data.evisa?.evisa_orders_paid} khách</h5>
                            {/* <h5>Tổng số khách: {data.evisa?.evisa_orders_paid}</h5> */}
                            <Col md={12} className="d-flex">
                              <Col md={6}>
                                <h5>Loại thị thực Một lần: {data.evisa?.evisa_orders_paid_mot_lan}</h5>
                                <h5>Loại thị thực Nhiều lần: {data.evisa?.evisa_orders_paid_nhieu_lan}</h5>
                              </Col>
                              <Col md={6}>
                                {data.evisa?.evisa_numberOfStay && data.evisa?.evisa_numberOfStay.map((item, index) => {
                                  return (
                                    <h5 key={index}>Loại thị thực {item.stayIn} ngày: {item.count}</h5>
                                  )
                                })}
                              </Col>
                            </Col>
                          </Col>
                          <Col className="p-3 rounded-3 border border-1 border-primary mt-2">
                            <div>
                              <h5 className="fw-bold">Số lượng theo khẩn EVISA</h5>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>STT</th>
                                    <th>Loại khẩn</th>
                                    <th>Số lượng</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.evisa?.response_urgent_evisa &&
                                    data.evisa?.response_urgent_evisa.map((item, index) => {
                                      if(Number(item.evisa_paid) > 0) {
                                        return (  
                                          <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.title}</td>
                                            <td>{item.evisa_paid}</td>
                                          </tr>
                                        )
                                      }
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                  {/* )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

Statistical.prototype = {}

export default Statistical
