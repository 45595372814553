const janTopSellingData = [
  {
    name: "Product A",
    desc: "Neque quis est",
    value: 37
  },
  {
    name: "Product B",
    desc: "Quis autem iure",
    value: 72
  },
  {
    name: "Product C",
    desc: "Sed aliquam mauris.",
    value: 54
  }
]

const decTopSellingData = [
  {
    name: "Product D",
    desc: "Neque quis est",
    value: 18
  },
  {
    name: "Product E",
    desc: "Quis autem iure",
    value: 51
  },
  {
    name: "Product F",
    desc: "Sed aliquam mauris.",
    value: 48
  }
]

const novTopSellingData = [
  {
    name: "Product G",
    desc: "Neque quis est",
    value: 27
  },
  {
    name: "Product H",
    desc: "Quis autem iure",
    value: 75
  },
  {
    name: "Product I",
    desc: "Sed aliquam mauris.",
    value: 39
  }
]

const octTopSellingData = [
  {
    name: "Product J",
    desc: "Neque quis est",
    value: 56
  },
  {
    name: "Product K",
    desc: "Quis autem iure",
    value: 41
  },
  {
    name: "Product L",
    desc: "Sed aliquam mauris.",
    value: 20
  }
]

const janEarningData = [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76]

const decEarningData = [42, 19, 32, 51, 49, 44, 14, 56, 68, 82, 68, 76]

const novEarningData = [31, 40, 36, 51, 49, 72, 69, 12, 35, 42, 18, 76]

const octEarningData = [31, 40, 49, 44, 14, 56, 69, 31, 40, 36, 51]

const chatData = [
  {
    id: 1,
    name: "Steven Franklin",
    msg: "Hello!",
    time: "10.00",
    isSender: false
  },
  {
    id: 2,
    name: "Admin",
    msg: "Hi, How are you? What about our next meeting?",
    time: "10.02",
    isSender: true
  },
  {
    id: 3,
    name: "Steven Franklin",
    msg: "Yeah everything is fine",
    time: "10.06",
    isSender: false
  },
  {
    id: 4,
    name: "Steven Franklin",
    msg: "& Next meeting tomorrow 10.00AM",
    time: "10.06",
    isSender: false
  },
  {
    id: 5,
    name: "Admin",
    msg: "Wow that's great",
    time: "10.07",
    isSender: true
  }
]

export {
  janTopSellingData,
  decTopSellingData,
  novTopSellingData,
  octTopSellingData,
  janEarningData,
  decEarningData,
  novEarningData,
  octEarningData,
  chatData
}
