import React from "react"
import classnames from "classnames"
import { Nav, NavLink } from "reactstrap"

export default function NavStep({ activeTab = "1", setactiveTab }) {
  const items = [
    {
      id: "1",
      label: "Information",
      icon: <i className="bx bxs-info-circle d-block check-nav-icon mt-4 mb-2" />
    },
    {
      id: "2",
      label: "Configs",
      icon: <i className="bx bxs-cog d-block check-nav-icon mt-4 mb-2" />
    }
  ]

  return (
    <Nav className="flex-column" pills>
      {items.length > 0 &&
        items.map((item, index) => (
          <NavLink
            key={item.id}
            className={classnames({
              "bg-success text-light": Number(item.id) < Number(activeTab),
              active: Number(item.id) == Number(activeTab)
            })}
            onClick={() => setactiveTab(item.id)}
            disabled={true}
          >
            {item.icon}
            <p className="fw-bold mb-4">{item.label}</p>
          </NavLink>
        ))}
    </Nav>
  )
}
