import React, { forwardRef, useEffect, useState } from "react"

const FlagsCellRenderer = forwardRef(props => {
  const [pathIco, setPathIco] = useState("")

  // useEffect(() => {
  //   fetch("https://restcountries.com/v3.1/name/" + props.data.quoc_tich + "?fields=flags")
  //     .then(response => response.json())
  //     .then(data => {
  //       const country = data[0] // Lấy quốc gia đầu tiên trong trường hợp này
  //       if (country) {
  //         const flagUrl = country.flags.svg // Lấy URL của biểu tượng
  //         // Hiển thị biểu tượng trên giao diện nếu cần
  //         setPathIco(flagUrl)
  //       }
  //     })
  //     .catch(error => console.error("Lỗi:", error))

  //   return () => {}
  // }, [])

  return (
    <div className="d-flex gap-1 align-items-center justify-content-end">
      {/* {pathIco && (
        <>
          <img
            alt={props.data.quoc_tich}
            src={pathIco}
            style={{ width: "25px", height: "15px", objectFit: "contain" }}
          />
        </>
      )} */}

      <span>{props.data.quoc_tich}</span>
    </div>
  )
})

FlagsCellRenderer.displayName = "FlagsCellRenderer"

export default FlagsCellRenderer
