import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import { Container, Button } from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { Link } from "react-router-dom"
import { ListPartnerResult, getPartnerResult, CloneUserDoitac } from "services/"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import AgAction from "./AgCellRendering/AgAction"

import quoctich from "../Option/quoctich.json"

import PartnerChargeModal from "./PartnerChargeModal"
import NewPartnerNameModal from "./NewPartnerNameModal"

import moment from "moment"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"
import Swal from "sweetalert2"

// Loading
import LoadingContext from "context/loading.context"

const ListParter = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState([])

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const [isOpenModal, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState()
  const [originalUsername, setOriginalUsername] = useState("")
  const [newUsername, setNewUsername] = useState("")
  const [isOpenModalCloneUser, setIsOpenModalCloneUser] = useState(false)
  const { setLoading } = useContext(LoadingContext)

  const toggle = () => {
    if (isOpenModal) {
      setModalOpen(false)
      setModalData(null)
    } else {
      setModalOpen(true)
    }
  }

  const toggleClone = () => {
    if (isOpenModalCloneUser) {
      setIsOpenModalCloneUser(false)
      setNewUsername("")
      setOriginalUsername("")
    } else {
      setIsOpenModalCloneUser(true)
    }
  }

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  let gridStyle = { minHeight: 750, marginTop: 10, width: "100%" }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const fetchData = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    const { data } = await ListPartnerResult(payload)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      let country = quoctich.find(element => element.value == item.country)
      temp.push({
        ...item,
        id: index + 1,
        gender: item.gender === true ? t("Male") : t("Female"),
        country: country?.label,
        action_view: () => {
          fetchModelContent(item._id)
        },
        action_clone: () => {
          setOriginalUsername(item.username)
          toggleClone()
        }
      })
    })

    // Deplay loading
    if (data.length > 100) {
      setLoading(false)
      setData(temp)
    } else {
      setTimeout(() => {
        setLoading(false)
        setData(temp)
      }, 500)
    }
  }

  const fetchModelContent = async id => {
    const response = await getPartnerResult(id)
    if (response.data == null || response.status != 200) {
      Swal.fire({
        title: "Error",
        text: "Invalid partner. Please try again",
        icon: "error",
        timer: 2000
      })
      navigate("/partner/list")
      return false
    }

    const partner = response.data
    const dataInfo = {
      user_id: partner.user?._id,
      username: partner.user?.username,
      password: partner.user?.password,
      fullname: partner.user?.fullname,
      email: partner.user?.email,
      dob: partner.user?.dob != undefined ? new Date(partner.user.dob) : null,
      country: partner.user?.country ? quoctich.find(element => element.value == partner.user.country) : "",
      phone: partner.user?.phone,
      address: partner.user?.address,
      code: partner.user?.code
    }

    const dataConfig = {
      id: partner.charge_model?._id,
      username: dataInfo.username,
      country: dataInfo.country,
      phone: dataInfo.phone,
      email: dataInfo.email,
      address: dataInfo.address,
      dob: dataInfo.dob,
      code: dataInfo.dob,
      urgent_model_CVT: partner.charge_model?.urgent_model_CVT || [],
      urgent_model_EVISA: partner.charge_model?.urgent_model_EVISA || [],
      ma_doi_tac: partner.charge_model?.ma_doi_tac
    }
    setModalData(dataConfig)
    toggle()
  }

  const cloneNewUser = async () => {
    if (originalUsername !== "" && newUsername !== "") {
      CloneUserDoitac({ ma_doi_tac_old: originalUsername, ma_doi_tac_new: newUsername })
        .then(res => {
          Swal.fire({
            title: "Thành công",
            icon: "success"
          })
          window.location.reload()
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            title: "Lỗi",
            icon: "error"
          })
        })
    }
  }

  useEffect(() => {
    fetchData()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Username"),
      field: "username"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Full name"),
      field: "fullname"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Sex"),
      field: "gender"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Country"),
      field: "country"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Phone number"),
      field: "phone"
    },
    {
      headerName: t("ACTION"),
      field: "",
      sortable: false,
      resizable: false,
      floatingFilter: false,
      cellRendererSelector: params => {
        if (params.data) return { component: AgAction }
      }
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <PartnerChargeModal isOpen={isOpenModal} toggle={toggle} data={modalData} />
        <NewPartnerNameModal
          isOpen={isOpenModalCloneUser}
          toggle={toggleClone}
          setNewUsername={setNewUsername}
          cloneNewUser={cloneNewUser}
        />
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Quản lý đối tác")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      gridRef.current.api.deselectAll()
                    }}
                  >
                    {t("Clear row selected")}
                  </Button>
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
              <Link to="/partner/create" color="success" className="btn-rounded mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1" />
                {t("New Partner")}
              </Link>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListParter
