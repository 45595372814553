import React, { useMemo, useState, useEffect, useRef, useContext } from "react"
import { Container, Button } from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { ListPassportVerifiedResult } from "services/"

import { useTranslation } from "react-i18next"
import { getHeight } from "helpers/data_grid_helper"

import ModalDetail from "./Modal"
import ModalDetailCv from "./Modal/detail"

//Cell rendering
import AgButtonImage from "./AgCellRendering/AgButtonImage"
// import AgButtonDetail from "./AgCellRendering/AgButtonDetail"
import AgText from "./AgCellRendering/AgText"
import AgTextSpan from "./AgCellRendering/AgTextSpan"
import AgVip from "./AgCellRendering/AgVip"

import moment from "moment"
import { useNavigate } from "react-router-dom"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

import LoadingContext from "context/loading.context"

const ListPassportVerified = () => {
  const gridRef = useRef(null)
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [item, setItem] = useState({})
  const [data, setData] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const [itemDetail, setItemDetail] = useState({})
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const { setLoading } = useContext(LoadingContext)

  const navigate = useNavigate()

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
      setItem({})
    } else {
      setIsOpen(true)
    }
  }

  const toggleDetail = () => {
    if (isOpenDetail) {
      setIsOpenDetail(false)
    } else {
      setIsOpenDetail(true)
    }
  }

  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    console.log(height)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const fetchData = async () => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    const { data } = await ListPassportVerifiedResult(payload)
    setLoading(false)
    if (data?.length === 0) return
    let temp = []
    data.map((item, index) => {
      temp.push({
        ...item,
        createAt: moment(item.create_at).format("DD/MM/YYYY HH:mm:ss"),
        fullname: item.surname + " " + item.name,
        gender: item.sex === true ? t("Male") : t("Female"),
        urgent_title: item && item.fee !== undefined ? item.fee.title_vn : "",
        vip: item.vip === true ? t("Yes") : t("No"),
        multipleEntry_title: Number(item.multipleEntry) === 1 ? t("Many times") : t("Once time")
      })
    })
    setData(temp)
  }

  useEffect(() => {
    fetchData()
  }, [t, startDate, endDate])

  const COLUMN_DEFS_DEFAULT = {
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  }

  const columnDefs = [
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Date Created"),
      field: "createAt",
      pinned: "left"
    },
    {
      headerName: t("Partner code"),
      field: "ma_doi_tac",
      pinned: "left",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Fullname"),
      field: "fullname",
      pinned: "left"
    },
    {
      headerName: t("Image"),
      field: "passport_img",
      resizable: true,
      cellRendererSelector: params => {
        const image = {
          component: AgButtonImage
        }
        if (params.data) {
          return image
        }
      },
      cellRendererParams: {
        setItem: setItem,
        toggle: toggle,
        color: "danger"
      },
      pinned: "left"
    },
    // {
    //   headerName: t("Detail"),
    //   field: "mahoso",
    //   resizable: true,
    //   cellRendererSelector: params => {
    //     const detail = {
    //       component: AgButtonDetail
    //     }
    //     if (params.data) {
    //       return detail
    //     }
    //   },
    //   cellRendererParams: {
    //     navigate,
    //     color: "danger",
    //     toggleDetail,
    //     setItemDetail
    //   },
    //   pinned: "left"
    // },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("File number"),
      field: "mahoso"
      // minWidth: 250
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Customer segmentation"),
      field: "phantachkhachhang"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Date of birth"),
      field: "dob"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Gender"),
      field: "gender"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Passport number"),
      field: "personal_id"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Urgent"),
      field: "urgent_title",
      cellRendererSelector: params => {
        const text = {
          component: AgText
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgTextSpan
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Number of days of stay"),
      field: "stayIn"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Date of Entry"),
      field: "entry_date"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Departure Date"),
      field: "departure_date"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Nationality"),
      field: "country"
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Vip"),
      field: "vip",
      cellRendererSelector: params => {
        const text = {
          component: AgVip
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Visa receiving place"),
      field: "addToGetVisa",
      minWidth: 500
    },
    {
      ...COLUMN_DEFS_DEFAULT,
      headerName: t("Employee Submits"),
      field: "ma_nhan_vien"
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <ModalDetail isOpen={isOpen} item={item} toggle={toggle} />
        <ModalDetailCv isOpen={isOpenDetail} item={itemDetail} toggle={toggleDetail} />
        <Container fluid>
          <div className="js-height">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{t("Danh sách khách hàng đã nộp DVC")}</h4>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex me-3">
                  <Button
                    className=""
                    onClick={() => {
                      gridRef.current.api.setFilterModel(null)
                    }}
                  >
                    {t("Clear filter")}
                  </Button>
                </div>
                <div className="d-flex me-3" style={{ height: "20px" }}>
                  <div className="vr"></div>
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setStartDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <DatePicker
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate || ""}
                    onChange={e => {
                      setSelectedDate(null)
                      setEndDate(e)
                    }}
                  />
                </div>
                <div className="me-2">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    options={optionDateSelect}
                    value={selectedDate}
                    placeholder={t("Select time")}
                    onChange={e => {
                      setSelectedDate(e)
                      handleDateSelect(e.value)
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleDateSelect()
                  }}
                  color="danger"
                >
                  {" "}
                  {t("Reset")}{" "}
                </Button>
              </div>
            </div>
            {validateStartAndEnd() === true && (
              <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                {t("Check your start or end date again")}.
              </p>
            )}
          </div>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListPassportVerified
