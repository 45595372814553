import React from "react"
import { useFormikContext } from "formik"

import { Input as InputReact, FormGroup, Label, FormFeedback } from "reactstrap"

export default function Input({ label, id, name, type = "text", inputClass, required = false }) {
  const { values, errors, handleChange, handleBlur, touched } = useFormikContext()

  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} className="col-form-label">
          {label}
          {required && (
            <>
              <span className="text-danger">*</span>
            </>
          )}
        </Label>
      )}
      {["text", "email", "number", "textarea", "password"].includes(type) && (
        <InputReact
          type={type}
          className={inputClass}
          placeholder={label}
          name={name}
          id={id}
          value={values[name] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={touched[name] && errors[name] ? true : false}
        />
      )}
      {errors[name] ? <FormFeedback>{errors[name]}</FormFeedback> : null}
    </FormGroup>
  )
}
