import React, { useState, useCallback, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Row, Col, TabContent, TabPane, Card, CardBody, Alert } from "reactstrap"

import { getPartnerResult } from "../../../services/"

import { Link } from "react-router-dom"
import { CreateConfigPartner, UpdateConfigPartner, UpdatePartner as UpdatePartnerApi } from "../../../services/"
import withRouter from "components/Common/withRouter"
import Swal from "sweetalert2"

import NavStep from "./Element/NavStep"
import ContentStep1 from "./Element/ContentStep1"
import ContentStep2 from "./Element/ContentStep2"
import quoctich from "../Option/quoctich.json"

const UpdatePartner = ({ router }) => {
  //meta title
  document.title = "Update partner"
  const params = router?.params

  const refFormInfo = useRef(null)

  const [activeTab, setactiveTab] = useState("1")
  const [dataInfoDefault, setDataInfoDefault] = useState({})
  const [dataChargeModel, setDataChargeModel] = useState({})
  const [data, setData] = useState({ username: "" })
  const [message, setMessage] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const onDismissAlert = () => setShowAlert(false)

  const gender = [
    { value: false, label: "Female" },
    { value: true, label: "Male" }
  ]

  const navigate = useNavigate()

  const handleCancel = useCallback(async () => {
    Swal.fire({
      title: "Are you sure you want to cancel editing?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: true
    }).then(result => {
      if (result.isConfirmed) {
        navigate("/partner/list")
      }
    })
  }, [])

  const fetchPartner = useCallback(async () => {
    const response = await getPartnerResult(params?.id)
    if (response.data == null || response.status != 200) {
      Swal.fire({
        title: "Error",
        text: "Invalid partner. Please try again",
        icon: "error",
        timer: 2000
      })
      navigate("/partner/list")
      return false
    }

    const partner = response.data
    const dataInfo = {
      user_id: partner.user?._id,
      username: partner.user?.username,
      password: partner.user?.password,
      fullname: partner.user?.fullname,
      email: partner.user?.email,
      dob: partner.user?.dob != undefined ? new Date(partner.user.dob) : null,
      gender: partner.user?.gender != undefined ? gender.find(element => element.value == partner.user.gender) : "",
      country: partner.user?.country ? quoctich.find(element => element.value == partner.user.country) : "",
      phone: partner.user?.phone,
      address: partner.user?.address,
      code: partner.user?.code
    }
    setDataInfoDefault(dataInfo)

    const dataConfig = {
      id: partner.charge_model?._id,
      urgent_model_CVT: partner.charge_model?.urgent_model_CVT || [],
      urgent_model_EVISA: partner.charge_model?.urgent_model_EVISA || [],
      ma_doi_tac: partner.charge_model?.ma_doi_tac
    }

    setDataChargeModel(dataConfig)
    setData({ ...dataInfo, ...dataConfig })
  }, [])

  useEffect(() => {
    fetchPartner()
  }, [])

  const handleSubmitInfo = useCallback(
    async (values, actions) => {
      setData({ ...data, ...values, code: values.username })
      let dataInfo = {
        user_id: data?.user_id,
        username: values?.username,
        password: values?.password,
        fullname: values?.fullname,
        email: values?.email,
        dob: values?.dob != undefined ? new Date(values?.dob) : null,
        gender: values.gender,
        country: values.country,
        phone: values.phone,
        address: values.address,
        code: values.username
      }

      try {
        if (JSON.stringify(dataInfo) != JSON.stringify(dataInfoDefault)) {
          const response = await UpdatePartnerApi({
            ...dataInfo,
            gender: dataInfo.gender?.value,
            country: dataInfo.country?.value
          })
          if (response.status != 201) throw new Error("")
          setDataInfoDefault(dataInfo)
        }
        setactiveTab("2")
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.msg || "An error occurred while creating a new partner. Please try again",
          icon: "error",
          timer: 2000
        })
      }
    },
    [activeTab, data, dataInfoDefault]
  )

  const handleSubmit = useCallback(async () => {
    if (!data) return

    let model_CVT = []
    if (data?.urgent_model_CVT != undefined && data?.urgent_model_CVT?.length > 0) {
      data.urgent_model_CVT.map((value, index) => model_CVT.push({ ...value, id: String(index + 1) }))
    }

    let model_EVISA = []
    if (data?.urgent_model_EVISA != undefined && data?.urgent_model_EVISA?.length > 0) {
      data.urgent_model_EVISA.map((value, index) => model_EVISA.push({ ...value, id: String(index + 1) }))
    }

    let dataConfig = {
      id: data.id,
      urgent_model_CVT: model_CVT,
      urgent_model_EVISA: model_EVISA,
      ma_doi_tac: data.username
    }

    try {
      if (JSON.stringify(dataConfig) != JSON.stringify(dataChargeModel)) {
        let response = null
        if (dataConfig.id == undefined) {
          response = await CreateConfigPartner(dataConfig)
        } else {
          response = await UpdateConfigPartner(dataConfig)
        }

        if (response.status != 201) throw new Error("")
        setDataChargeModel(dataConfig)
      }
      await Swal.fire({
        title: "Success",
        text: "Update Partner successfully",
        icon: "success",
        timer: 2000
      })
      navigate("/partner/list")
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: "Error",
        text:
          error?.response?.data?.msg ||
          error?.response?.data?.message ||
          "An error occurred while update partner. Please try again",
        icon: "error",
        timer: 2000
      })
    }
  }, [data, dataChargeModel])

  const handleNext = useCallback(() => {
    setMessage("")
    setShowAlert(false)
    switch (activeTab) {
      case "1":
        refFormInfo.current.submitForm()
        break
      case "2":
        // let urgent_model_CVT = data?.urgent_model_CVT?.length;
        // let urgent_model_EVISA = data?.urgent_model_EVISA?.length;

        // if (urgent_model_CVT == undefined || urgent_model_CVT <= 0) {
        //   setMessage('You have not added urgent_model_CVT');
        //   setShowAlert(true);
        // } else if (urgent_model_EVISA == undefined || urgent_model_EVISA <= 0) {
        //   setMessage('You have not added urgent_model_EVISA');
        //   setShowAlert(true);
        // } else {
        handleSubmit()
        // }
        break
    }
  }, [activeTab, data])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="checkout-tabs">
            <Row>
              <Col lg="2" sm="3">
                <NavStep activeTab={activeTab} setactiveTab={setactiveTab} />
              </Col>
              <Col lg="10" sm="9">
                <Card>
                  <CardBody>
                    {message && (
                      <Alert color="danger" isOpen={showAlert} toggle={onDismissAlert}>
                        {message}
                      </Alert>
                    )}
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <ContentStep1
                          data={data}
                          setData={setData}
                          onSubmit={handleSubmitInfo}
                          refFormInfo={refFormInfo}
                          gender={gender}
                          quoctich={quoctich}
                        />
                      </TabPane>
                      <TabPane tabId="2" id="v-pills-confir" role="tabpanel">
                        <ContentStep2 data={data} setData={setData} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Row className="mt-4">
                  <Col sm="6">
                    <div className="text-sm-top">
                      <Link className="btn btn-danger" onClick={() => handleCancel()}>
                        <i className="bx bx-x"></i> CANCEL
                      </Link>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-end">
                      {Number(activeTab) == 2 && (
                        <Link className="btn btn-link me-3" onClick={() => setactiveTab("1")}>
                          <i className="bx bx-left-arrow-alt"></i> BACK
                        </Link>
                      )}
                      <Link className="btn btn-success" onClick={() => handleNext()}>
                        {Number(activeTab) < 2 && (
                          <>
                            NEXT <i className="bx bx-right-arrow-alt"></i>
                          </>
                        )}
                        {Number(activeTab) == 2 && (
                          <>
                            <i className="bx bxs-paper-plane me-1"></i> SUBMIT
                          </>
                        )}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UpdatePartner)
