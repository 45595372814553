export const API_URL = process.env.API_URL || "https://quanly.visavietnam.biz/api/v1"
// export const API_URL = process.env.API_URL || "https://visaviet.biz/api/v1"
// export const API_URL = process.env.API_URL || "https://quanlyv2.visavietnam.biz/api/v1"
// export const API_URL = process.env.API_URL || "http://localhost:8000/api/v1"
export const DATA_GRID_KEY =
  process.env.DATAGRID_KEY ||
  "AppName=ESPOTTECHNOLOGYJOINTSTOCKCOMPANYApp,Company=ESPOTTECHNOLOGYJOINTSTOCKCOMPANY,ExpiryDate=2024-08-18T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ESPOTTECHNOLOGYJOINTSTOCKCOMPANYLicenseRef,Z=986226287-1272928755-234746756-2084690133-1758818143205346779"
export const AG_GRID_KEY =
  process.env.AG_GRID_KEY || "DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6"
export const API_URL_PYTHON_EXPORT_EXCEL =
  process.env.API_URL_PYTHON_EXPORT_EXCEL || "https://quanly.visavietnam.biz/export_excel/api/insert_data"
