import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [haveDetail, setHaveDetail] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })

  const converTrangThai = value => {
    switch (value) {
      case "- Đã trả kết quả":
        // return <span className="badge rounded-pill badge-soft-success font-size-15">Đã xử lý</span>
        return t("Results returned")
      case "Đã trả kết quả":
        // return <span className="badge rounded-pill badge-soft-success font-size-15">Đã xử lý</span>
        return t("Results returned")
      case "Đang được xử lý.":
        return t("Processing")
      case "- Đang được xử lý.":
        return t("Processing")
      case "":
        return t("Cancel")
      default:
        return value
    }
  }

  return (
    <>
      {haveDetail && (
        <span
          className={
            props.value === "Mới đăng ký"
              ? "text-warning"
              : props.value === "Đang xử lý"
                ? "text-danger"
                : props.value === "Đã trả kết quả"
                  ? "text-success"
                  : ""
          }
        >
          {converTrangThai(props.value)}
        </span>
      )}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
