export const STORAGE_KEY = {
  auth: "authUser",
  code: "ma_nhan_vien",
  madoitac: "ma_doi_tac",
  refresh_token: "refreshToken"
}

export const STORAGE_KEY_TIMEOUT = {
  user: 3600,
  refresh_token: 3600 * 24 * 30
}
