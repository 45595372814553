import { Modal, ModalBody, Container, Col, Label, Input } from "reactstrap"
import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const ModalDetail = props => {
  let { isOpen, toggle, validationSubmit, handleCheckMahoso } = props

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog modal-dialog-centered">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Profile details")}:
          </h5>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container className="body" fluid={true}>
            <Col>
              <div>
                <Label htmlFor="so_ho_so" className="fs-5">
                  {props.t("File number")} <span className="text-danger">*</span>
                </Label>
                <div>
                  <Input
                    id="so_ho_so"
                    name="so_ho_so"
                    bsSize="lg"
                    value={validationSubmit.values.so_ho_so}
                    onChange={validationSubmit.handleChange}
                    onBlur={validationSubmit.handleBlur}
                    type="textarea"
                    className="form-control w-100"
                    placeholder="G01.108.000.000-231219-00287"
                    style={{ minHeight: "550px" }}
                    invalid={validationSubmit.touched.so_ho_so && validationSubmit.errors.so_ho_so ? true : false}
                  />
                </div>
                <div className="text-center mt-3">
                  <button
                    disabled={
                      (validationSubmit.touched.so_ho_so && validationSubmit.errors.so_ho_so) ||
                      validationSubmit.values.so_ho_so === ""
                        ? true
                        : false
                    }
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCheckMahoso}
                  >
                    {props.t("Check")}
                  </button>
                </div>
              </div>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalDetail.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalDetail))
